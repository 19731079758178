import * as React from 'react';
import { trackEvent } from '../../services/piwik.service';
import {
  addUserConsentSettingsCallback,
  getUserConsentOnQumu,
  loadQumuScript,
  removeUserConsentSettingsCallback,
} from '../CookieConsent/cookieConsent';
import { NoVideo } from '../CookieConsent/NoVideo';

import './VideoPlayer.scss';

export interface IVideoPlayerProps {
  videoId: string;
  startVideo: boolean;
  onStop: () => void;
}

export class VideoPlayer extends React.Component<IVideoPlayerProps> {
  // , IVideoPlayerState> {
  private qumuConsent = false;

  constructor(props: IVideoPlayerProps) {
    super(props);
    this.updateQumuConsent = this.updateQumuConsent.bind(this);
    this.updateConsent = this.updateConsent.bind(this);
    this.initializePlayer = this.initializePlayer.bind(this);
  }

  updateConsent() {
    this.updateQumuConsent(true);
  }

  componentDidMount() {
    this.updateQumuConsent(false);
    addUserConsentSettingsCallback(this.updateConsent);
  }

  componentWillUnmount() {
    removeUserConsentSettingsCallback(this.updateConsent);
  }

  updateQumuConsent(reload: boolean) {
    getUserConsentOnQumu().then((consent: boolean) => {
      this.qumuConsent = consent;
      if (reload) {
        this.forceUpdate();
      }
    });
  }

  initializePlayer() {
    const widget = (window as any).KV.widget({
      guid: this.props.videoId,
      selector: '#qumu-player',
      type: 'thumbnail',
      playerType: 'full',
      showInfoPanel: false,
      embed: true,
    });
    const api = widget.api;

    api.init(() => {
      // Video will be played...
      api.bind('play', () => {
        // Video is played. Add a Piwik event!
        trackEvent('video', 'play');
      });

      api.bind('unload', () => {
        this.props.onStop();
        setTimeout(() => {
          api.command('destroy');
        }, 1000);
      });

      // Play the video - not sure why it does not work when running it directly...
      setTimeout(() => {
        api.command('playKulu');
      });
    });
  }

  render() {
    if (!this.props.startVideo) {
      return null;
    }

    if (!this.qumuConsent) {
      return (
        <div className="mt-3">
          <NoVideo />
        </div>
      );
    }

    loadQumuScript().then(() => {
      this.initializePlayer();
    });

    return <div id="qumu-player" className="active" />;
  }
}
