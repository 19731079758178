import React, { FC, useState, useRef, useCallback } from 'react';
import { useGlobalContext } from '../../context/global.context';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';
import { useIntl } from 'react-intl';
import { InternalBadge } from '../Common/internal-badge/InternalBadgeComponent';
import { handleA11y } from 'common/utils';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-account-center': any;
    }
  }
}

export const AccountCenter: FC = () => {
  const accountCenterRef = useRef<HTMLElement | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();
  const context = useGlobalContext();
  const contextRef = useRef(context);
  contextRef.current = context;

  const changeLanguageListener: EventListener = useCallback((event: any) => {
    contextRef.current.changeLanguage(event.detail.language);
  }, []);

  const stopNavigateAsListener: EventListener = useCallback(() => {
    contextRef.current.endNavigateAsClient();
  }, []);

  const startNavigateAsListener: EventListener = useCallback(() => {
    setShowModal(true);
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const setMountedElement = (mountedElement: HTMLElement | null) => {
    if (!accountCenterRef.current && mountedElement) {
      mountedElement.addEventListener('sgwt-account-center--language-changed', changeLanguageListener);
      mountedElement.addEventListener('sgwt-account-center--navigate-as-link-clicked', startNavigateAsListener);
      mountedElement.addEventListener('sgwt-account-center--stop-navigation-as', stopNavigateAsListener);
    } else if (accountCenterRef.current && !mountedElement) {
      accountCenterRef.current.removeEventListener('sgwt-account-center--language-changed', changeLanguageListener);
      accountCenterRef.current.removeEventListener('sgwt-account-center--link-clicked', startNavigateAsListener);
      accountCenterRef.current.removeEventListener('sgwt-account-center--stop-navigation-as', stopNavigateAsListener);
    }
    accountCenterRef.current = mountedElement;
  };

  const selectNavigationAs = (as: string) => {
    if (as === 'internal') {
      context.endNavigateAsClient();
    } else {
      context.navigateAsClient();
    }
    setShowModal(false);
  };

  if (!context.user) {
    return null;
  }

  const navigateAs = ['internal', 'client'];
  const navigateAsLabel = {
    name: formatMessage({
      id: `global.navigate-as.${context.viewAsClient ? 'client' : 'internal'}`,
    }),
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
        className="mt-5 pt-5"
      >
        <ModalBody className="p-0" style={{ overflow: 'hidden' }}>
          {navigateAs.map(as => {
            const icon = (
              <i className="icon icon-xl text-socgen mr-3">
                {as === 'internal' ? 'person' : 'work'}
              </i>
            );
            return (
              <div
                className="h-50 border-bottom border-secondary p-4 pointer"
                key={`navigate-as-${as}`}
                role="button"
                tabIndex={0}
                onClick={handleA11y(() => selectNavigationAs(as))}
                onKeyUp={handleA11y(() => selectNavigationAs(as))}
              >
                {as === 'internal' && <InternalBadge />}
                {/* Navigate as choice for screen view */}
                <div className="d-none d-sm-flex px-3">
                  {icon}
                  <div className="mt-1">
                    <WrappedFormattedMessage
                      id={`global.navigate-as.${as}.title`}
                      tag="div"
                      className="h4"
                    />
                    <WrappedFormattedMessage
                      id={`global.navigate-as.${as}.description`}
                      tag="div"
                    />
                  </div>
                </div>
                {/* Navigate as choice for mobile view */}
                <div className="d-sm-none">
                  <div className="d-flex mb-2">
                    {icon}
                    <div className="mt-1">
                      <WrappedFormattedMessage
                        id={`global.navigate-as.${as}.title`}
                        tag="div"
                        className="h4"
                      />
                    </div>
                  </div>
                  <WrappedFormattedMessage
                    id={`global.navigate-as.${as}.description`}
                    tag="div"
                  />
                </div>
              </div>
            );
          })}
        </ModalBody>
      </Modal>
      {context.internalUser ? (
        <sgwt-account-center
          available-languages="fr,en"
          authentication="sg-connect-v2"
          mode="sg-markets"
          language={context.language}
          navigate-as
          navigate-as-user={JSON.stringify(navigateAsLabel)}
          ref={setMountedElement}
        />
      ) : (
        <sgwt-account-center
          available-languages="fr,en"
          authentication="sg-connect-v2"
          mode="sg-markets"
          language={context.language}
          ref={setMountedElement}
        />
      )}
    </>
  );
};
