import { IService, IServiceTheme, ISubCategorie } from '../data/data.types';
import { trackEvent } from './piwik.service';
import {
  CATEGORY_POST_TRADE,
  CATEGORY_PRE_TRADE,
  CLIENT_TYPE_PAGE_CATEGORY_ORDER,
  DEFAULT_CATEGORY_ORDER,
  MOBILE_SCROLL_THRESHOLD,
  THEME_LARGE_CORPORATES,
  THEME_RISK_FINANCE,
  THEME_SMALL_MEDIUM_ENTERPRISES,
  VALID_MAIL_DOMAINS,
} from '../common/constants';
import { IDocumentMetaOptions } from 'react-document-meta';
import { logTechnical } from '../common/logger';
import { LANGUAGE } from 'context/global.context';
/**
 * Split the services among all the categories.
 */
export function splitServicesAmongCategories(
  services: IService[]
): { [key: string]: IService[] } {
  const categories: Record<string, IService[]> = {};
  for (const service of services) {
    if (!service.category) {
      continue;
    }
    if (!categories.hasOwnProperty(service.category)) {
      categories[service.category] = [];
    }
    categories[service.category].push(service);
  }
  return categories;
}

/**
 * Get all themes from a set of services.
 */
export function getServicesThemes(services: IService[]): string[] {
  const themes: string[] = [];
  services.forEach((service: IService) => {
    for (const theme of service.themes) {
      if (themes.indexOf(theme) === -1) {
        themes.push(theme);
      }
    }
  });
  return themes;
}

/**
 * Get all existing client types.
 */
export function getServicesClientTypes(services: IService[]): string[] {
  const types: string[] = [];
  services.forEach((service: IService) => {
    if (service.clientTypes) {
      for (const type of service.clientTypes) {
        if (types.indexOf(type) === -1) {
          types.push(type);
        }
      }
    }
  });
  return types;
}

/**
 * Split the given services per theme.
 */
export function splitServicesByThemes(
  services: IService[]
): { [key: string]: IService[] } {
  const servicesPerTheme: { [key: string]: IService[] } = {};
  for (const service of services) {
    for (const theme of service.themes) {
      if (servicesPerTheme.hasOwnProperty(theme)) {
        servicesPerTheme[theme].push(service);
      } else {
        servicesPerTheme[theme] = [service];
      }
    }
  }
  return servicesPerTheme;
}

/**
 * Usefull to filter a services list by theme
 */
function serviceBelongToTheme(
  theme: IServiceTheme,
  service: IService
): boolean {
  if (theme.isCorporate) {
    return isServiceForCorporateThemes(theme.id, service);
  } else if (isRiskFinanceTheme(theme.id)) {
    return isRiskAndFinanceService(service);
  }

  return service.themes.findIndex(t => t === theme.id) !== -1;
}

/**
 * Split the given services per sub-category.
 */
function splitServicesBySubCategories(
  services: IService[]
): { [key: string]: IService[] } {
  const servicesPerSubCategory: { [key: string]: IService[] } = {};
  for (const service of services) {
    if (service.subCategory) {
      if (servicesPerSubCategory.hasOwnProperty(service.subCategory)) {
        servicesPerSubCategory[service.subCategory].push(service);
      } else {
        servicesPerSubCategory[service.subCategory] = [service];
      }
    }
  }
  return servicesPerSubCategory;
}

/**
 * Create the link (`mailto:`) to share a service by email.
 */
export function shareServiceLink(service: IService, language: LANGUAGE): string {
  const share = service.share[language];
  const subject = encodeURIComponent(share[0]);
  const body = encodeURIComponent(share[1]);
  const link = `https://info.sgmarkets.com/${language}/service/${servicePath(
    service
  )}`;
  return `mailto:?subject=${subject}&body=${body}%0D%0A${link}%0D%0A`;
}

/**
 * Check if a user is internal, based on its email address.
 */
export function isInternalUser(user: string | null): boolean {
  if (user === null) {
    return false;
  }
  const domain = user.split('@')[1];
  return VALID_MAIL_DOMAINS.indexOf(domain) > -1;
}

/**
 * Find the related services for the given service.
 * A service is related to another one if they share the same category & sub-category.
 */
export function getRelatedServices(
  service: IService,
  services: IService[]
): IService[] {
  return services.filter(
    (comparedService: IService) =>
      comparedService.serviceKey !== service.serviceKey &&
      service.category === comparedService.category &&
      service.subCategory === comparedService.subCategory
  );
}

/**
 * Return a function that sort category names.
 */
export function sortCategories(type?: 'client-types' | 'default') {
  const list =
    type === 'client-types'
      ? CLIENT_TYPE_PAGE_CATEGORY_ORDER
      : DEFAULT_CATEGORY_ORDER;
  return (cat1: string, cat2: string): number =>
    list.indexOf(cat1) - list.indexOf(cat2);
}

const isLargeCorporatesTheme = (theme: string) =>
  theme === THEME_LARGE_CORPORATES;
const isRiskFinanceTheme = (theme: string) => theme === THEME_RISK_FINANCE;

/**
 * Check, for Corporate themes pages, if a given service should be included in it.
 */
export function isServiceForCorporateThemes(
  theme: string,
  s: IService
): boolean {
  const largeCorpo = isLargeCorporatesTheme(theme);

  if (largeCorpo) {
    return s.themes.indexOf(THEME_LARGE_CORPORATES) > -1;
  }
  return s.themes.indexOf(THEME_SMALL_MEDIUM_ENTERPRISES) > -1;
}

export function isRiskAndFinanceService(s: IService) {
  return s.themes.indexOf(THEME_RISK_FINANCE) > -1;
}

/**
 * Sort sub-category names, with the highest priority to "Pre-trade", and the lowest to "Post-trade".
 */
export function sortSubCategories(
  subCategoryName1: string,
  subCategoryName2: string
): number {
  if (
    subCategoryName1 === CATEGORY_PRE_TRADE ||
    subCategoryName2 === CATEGORY_POST_TRADE
  ) {
    return -1;
  } else if (
    subCategoryName2 === CATEGORY_PRE_TRADE ||
    subCategoryName1 === CATEGORY_POST_TRADE
  ) {
    return 1;
  }
  return subCategoryName1.localeCompare(subCategoryName2);
}

export function computeThemeSubCategories(
  theme: IServiceTheme,
  services: IService[]
): ISubCategorie[] {
  services = services.filter((service: IService) =>
    serviceBelongToTheme(theme, service)
  );

  const splitedServices = theme.isCorporate
    ? splitServicesAmongCategories(services)
    : splitServicesBySubCategories(services);

  let namesOrdered = Object.keys(splitedServices);
  if (theme.isCorporate) {
    namesOrdered = namesOrdered.sort(sortCategories('client-types'));
  }

  return namesOrdered.map(name => ({
    name,
    services: splitedServices[name],
  }));
}

/**
 * Sort services by name.
 */
export function sortServices(service1: IService, service2: IService): number {
  return service1.name.localeCompare(service2.name);
}

function compareOnCategory(
  theme1: IServiceTheme,
  theme2: IServiceTheme
): number {
  if (theme1.isMarkets) {
    return theme2.isMarkets ? 0 : -1;
  }
  if (theme2.isMarkets) {
    return 1;
  }

  if (theme1.isCorporate) {
    return theme2.isCorporate ? 0 : -1;
  }
  return theme2.isCorporate ? 1 : 0;
}

function compareOnRank(theme1: IServiceTheme, theme2: IServiceTheme): number {
  return theme1.themeRank - theme2.themeRank;
}

export function sortThemes(
  theme1: IServiceTheme,
  theme2: IServiceTheme
): number {
  return compareOnCategory(theme1, theme2) || compareOnRank(theme1, theme2);
}

/**
 * Generate the path for the service, given its name (lowercased + use of hyphens).
 */
export function servicePath(service: IService): string {
  return service.name
    .toLowerCase()
    .replace(/&/g, 'and')
    .replace(/\s/g, '-');
}

export const noop = () => {
  // no op
};

/**
 * For mobile view, should we toggle the menu visibility?
 * It is hidden when we scroll down after a specific threshold, and made it visible when we scroll up.
 */
export function toggleMobileMenu(
  lastScroll: number,
  menuHidden: boolean
): boolean {
  const top = document.documentElement!.scrollTop;
  const down = top > lastScroll;
  const hide = !menuHidden && top > MOBILE_SCROLL_THRESHOLD && down; // the menu should be hidden
  const show = menuHidden && !down; // the menu should be shown again
  return hide || show;
}

/**
 * Get the default metadata for the current page.
 */
export function getPageMetadata(page?: string): IDocumentMetaOptions {
  const info = page || 'Information';
  return {
    title: `SG Markets Services - ${info}`,
    description: `SG Markets Services - ${info}`,
    meta: {
      property: {
        'og:title': `SG Markets Services - ${info}`,
      },
    },
  };
}

/**
 * Returns an array shuffled by the Durstenfeld shuffle algorithm (Fisher-Yates variant).
 * http://en.wikipedia.org/wiki/Fisher-Yates_shuffle#The_modern_algorithm
 */
export function shuffleArray(array: any[]): any[] {
  const shuffled = [...array];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

// tslint:disable no-console
/**
 * Basic logger...
 */
const sendTechnicalLog = (
  type: 'debug' | 'info' | 'warn' | 'error',
  ...msg: any[]
) => {
  const message = msg.join(' | ');
  console.log(`${type.toLocaleUpperCase()}`, ...msg);
  trackEvent(`log:${type}`, message);
  logTechnical(type, `[Web.Info] ${message}`);
};

export const logger = {
  debug: (...msg: any[]) => {
    if (process.env.NODE_ENV !== 'production') {
      sendTechnicalLog('debug', msg);
    }
  },
  info: (...msg: any[]) => sendTechnicalLog('info', msg),
  warn: (...msg: any[]) => sendTechnicalLog('warn', msg),
  error: (...msg: any[]) => sendTechnicalLog('error', msg),
};
// tslint:enable no-console
