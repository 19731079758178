import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ResponsiveText } from '../Common/ResponsiveText';
import { IService } from '../../data/data.types';
import { ServiceAccessButton } from '../Common/ServiceAccessButton';
import { getCompatiblePlatforms } from '../../services/ui.service';
import { useGlobalContext } from '../../context/global.context';
import { ServiceActions } from './ServiceActions';
import { InternalBadge } from '../Common/internal-badge/InternalBadgeComponent';

export interface IServiceHeadingProps {
  service: IService;
}

export const ServiceHeading: FC<IServiceHeadingProps> = ({ service }) => {
  const context = useGlobalContext();
  const { language } = context;

  const platforms = getCompatiblePlatforms(service).map(
    (platform: string) => (
      <FormattedMessage
        id={`platform.${platform}`}
        key={`supported-platform-${platform}`}
      >
        {txt => (
          <i
            className="icon ml-1 mr-1"
            title={typeof txt === 'string' ? txt : ''}
          >
            {platform}
          </i>
        )}
      </FormattedMessage>
    )
  );
  const link = service.category ? (
    <Link to={`/${language}/category/${service.category}`}>
      <FormattedMessage id={`category.${service.category}`} />
    </Link>
  ) : (
    <FormattedMessage id="category.no-category" />
  );
  const heading = (
    <>
      <ResponsiveText
        tagName="h1"
        text={service.name}
        mobileClasses="text-xlarge font-weight-medium mb-0"
        screenClasses="display-3 font-weight-medium mb-1"
      />
      <h4 className="mb-4 text-secondary d-none d-sm-block">{link}</h4>
      <h4 className="text-secondary d-sm-none fs-14">{link}</h4>
      <div className="d-sm-none text-secondary">{platforms}</div>
    </>
  );
  const themes = (
    <p className="text-large mb-3">
      {service.themes.map(theme => (
        <Link
          to={`/${language}/theme/${theme}`}
          key={theme}
          className="badge badge-lg sgbs-badge-default badge-prepend-square mr-1"
        >
          <FormattedMessage id={`theme.${theme}`} />
        </Link>
      ))}
    </p>
  );

  return (
    <div className="row service-header">
      {/* Initial box */}
      <div className="col-4 col-md-2">
        <div className="mr-3 d-inline-block border w-100 initials-box">
          <ResponsiveText
            tagName="span"
            text={service.initials}
            display="d-sm-inline-flex"
            className="text-uppercase font-weight-normal text-light"
            mobileClasses="display-3"
            screenClasses="display-2"
          />
          {service.isInternal && <InternalBadge />}
        </div>
      </div>

      <div className="col-8 pl-0 d-sm-none">
        {/* Name + category */}
        {heading}
      </div>

      <div className="col-sm-8 d-none d-sm-block">
        {/* Name + category */}
        {heading}

        {/* Themes, screen view */}
        {themes}

        {/* Share + JIVE, screen view */}
        <div className="fs-14 text-secondary">
          <span className="mr-3">
            <FormattedMessage id="service.compatiblePlatforms" />
            {platforms}
          </span>
          <ServiceActions service={service} />
        </div>
      </div>

      {/* Access button, screen view */}
      <div className="d-none d-sm-block col-md-2">
        <ServiceAccessButton
          service={service}
          className="btn-xl float-right"
          requestAccessClassBtnName="btn-xl"
        />
      </div>
      {/* Access button, mobile view */}
      <div className="d-sm-none col-12 mt-3">
        <ServiceAccessButton
          service={service}
          className="btn-xl btn-block"
          requestAccessClassBtnName="btn-xl"
        />
      </div>
    </div>
  );
};
