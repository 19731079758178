import React, { FC } from 'react';
import { useGlobalContext } from '../../../context/global.context';
import { WrappedFormattedMessage } from '../../Common/WrappedFormattedMessage';
import {
  IService,
  IServiceTheme,
  ISubCategorie,
} from '../../../data/data.types';
import { computeThemeSubCategories } from '../../../services/utils.service';
import { ServiceMiniCard } from '../../Service/ServiceMiniCard';

interface ICategoriesSectionProps {
  theme: IServiceTheme;
}

export const CategoriesSection: FC<ICategoriesSectionProps> = ({ theme }) => {
  const context = useGlobalContext();
  const subCategories = computeThemeSubCategories(theme, context.services);

  return (
    <div className="row">
      <div className="container">
        {subCategories.map(({ services, name }: ISubCategorie) => (
          <div key={`sub-category-${name}`}>
            <div className="row">
              <WrappedFormattedMessage
                id={`${theme.isCorporate ? '' : 'sub-'}category.${name}`}
                tag="h3"
                className="display-4 mt-5 mb-4 col-12"
              />
            </div>
            <div className="row">
              {services.map((service: IService) => (
                <div
                  className="col-12 col-sm-6"
                  key={`sub-category-${name}-service-${service.serviceKey}`}
                >
                  <ServiceMiniCard service={service} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
