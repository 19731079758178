import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export interface IWrappedFormattedMessageProps {
  id: string;
  className?: string;
  tag: string;
  values?: { [key: string]: string };
}

export const WrappedFormattedMessage: FC<IWrappedFormattedMessageProps> = (props) => {
  const CustomTag = `${props.tag}` as keyof JSX.IntrinsicElements;
  return (
    <FormattedMessage id={props.id} values={props.values}>
      {txt => <CustomTag className={props.className}>{txt}</CustomTag>}
    </FormattedMessage>
  );
};
