import React, { FC } from 'react';
import { IService } from '../../data/data.types';
import { InternalBadge } from './internal-badge/InternalBadgeComponent';

export interface IServiceInitialsBoxProps {
  service: IService;
}

export const ServiceInitialsBox: FC<
  IServiceInitialsBoxProps
> = (props: IServiceInitialsBoxProps) => {
  const { service } = props;
  const smallFont = service.initials.length > 2;
  return (
    <div
      className={`service-initials-box py-1 mr-2 border border-primary display-number text-primary ${smallFont &&
        'small'}`}
    >
      {service.isInternal && <InternalBadge small />}
      {service.initials}
    </div>
  );
};
