import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { THEME_PAGE_MAX_DICOVER_MORE_THEMES } from '../../../common/constants';
import { useGlobalContext } from '../../../context/global.context';
import { IServiceTheme } from '../../../data/data.types';
import { sortThemes } from '../../../services/utils.service';
import { ResponsiveComponent } from '../../Common/ResponsiveComponent';
import { ThemeCard } from '../ThemeCard';

export interface IDiscoverSectionProps {
  theme: IServiceTheme;
}

export const DiscoverSection: FC<IDiscoverSectionProps> = ({ theme }) => {
  const context = useGlobalContext();

  const themes = context.themes
    .filter((t: IServiceTheme) => t.id !== theme.id)
    .sort(sortThemes)
    .slice(0, THEME_PAGE_MAX_DICOVER_MORE_THEMES);

  const moreScreen = themes.map((t: IServiceTheme) => (
    <div className="col-sm-6 col-md-3 mb-3" key={`cards-theme-${t.id}`}>
      <ThemeCard themeId={t.id} mode="portrait" />
    </div>
  ));

  const moreMobile = themes.map((t: IServiceTheme) => (
    <div
      className="mb-3 pl-3"
      key={`cards-theme-mobile-${t.id}`}
      style={{ width: '60vw' }}
    >
      <ThemeCard themeId={t.id} mode="portrait" />
    </div>
  ));

  return (
    <div className="bg-lvl2 row">
      <div className="container">
        <div className="row mt-5 mb-3">
          <h3 className="col-12">
            <FormattedMessage id="home.discover" />
          </h3>
        </div>
        <div className="mb-5">
          <ResponsiveComponent
            componentForMobile={moreMobile}
            mobileClasses="pr-3"
            componentForScreen={moreScreen}
          />
        </div>
      </div>
    </div>
  );
};
