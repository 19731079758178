import { createContext, useContext } from 'react';
import { IAward, IService, IServiceTheme } from '../data/data.types';
import { noop } from '../services/utils.service';
import allThemes from '../data/all-themes.json';
import awards from '../data/awards.json';

export enum LANGUAGE {
  EN = 'en',
  FR = 'fr'
}

export interface IGlobalContext {
  user: string | null;
  internalUser: boolean;
  viewAsClient: boolean;
  language: LANGUAGE;
  categories: { [key: string]: IService[] };
  themes: IServiceTheme[];
  clientTypes: string[];
  retrievedServices: IService[]; // List of services retrieved from API
  services: IService[]; // List of services available in the current context
  accessibleServices: string[]; // List of services accessible to the user
  awards: IAward[];
  dataLoaded: boolean;
  dataError: any | null;
  // Getters
  getTheme: (key: string) => IServiceTheme | null;
  getCategoryServices: (category: string) => IService[];
  getSubCategoryServices: (subCategory: string) => IService[];
  getThemeServices: (theme: string) => IService[];
  sortThemeNames: (themes: string[]) => string[];
  // Actions
  changeLanguage: (newLanguage: string) => void;
  setUser: (user?: any) => void;
  navigateAsClient: () => void;
  endNavigateAsClient: () => void;
}

const getLanguage = () => {
  return document.location!.pathname.match(new RegExp('^/fr', 'i')) !== null
    ? LANGUAGE.FR
    : LANGUAGE.EN;
};

export const defaultContext: IGlobalContext = {
  user: null,
  viewAsClient: false,
  internalUser: false,
  language: getLanguage(),
  themes: allThemes.filter((t) => !t.isInternalOnly) as IServiceTheme[],
  categories: {},
  clientTypes: [],
  retrievedServices: [],
  services: [],
  accessibleServices: [],
  awards: awards,
  dataLoaded: false,
  dataError: null,
  // Getters
  getTheme: () => null,
  getCategoryServices: () => [],
  getSubCategoryServices: () => [],
  getThemeServices: () => [],
  sortThemeNames: () => [],
  // Actions (defined later)
  changeLanguage: noop,
  setUser: noop,
  navigateAsClient: noop,
  endNavigateAsClient: noop,
};

export const GlobalContext = createContext<IGlobalContext>(
  defaultContext
);

export const useGlobalContext = () => useContext(GlobalContext);
