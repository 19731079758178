import React, { FC, useState } from 'react';
import { BasicDropdown } from '../Common/BasicDropdown';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { MiniFooter } from '../Header/MiniFooter';
import { sortCategories } from '../../services/utils.service';
import { useGlobalContext } from '../../context/global.context';
import { signIn } from '../../services/api.service';
import logo from '../../assets/images/brand-main-transparent.png';

import './HomeFooter.scss';

export interface IHomeFooterState {
  showRelated: boolean;
  showServices: boolean;
}

const relatedLinks = [
  {
    id: 'footer.relatedLinks.wholesale',
    link: 'https://wholesale.banking.societegenerale.com',
  },
  {
    id: 'footer.relatedLinks.investorRelations',
    link: 'http://www.investor.socgen.com',
  },
  {
    id: 'footer.relatedLinks.iOS',
    link: 'https://itunes.apple.com/app/sg-markets/id1340368721?mt=8',
  },
];

export const HomeFooter: FC = () => {
  const [showRelated, setShowRelated] = useState(false);
  const [showServices, setShowServices] = useState(false);

  const context = useGlobalContext();

  const changeLanguage = (newLanguage: string): void => {
    context.changeLanguage(newLanguage);
  };

  const toggleRelated = (): void => {
    setShowRelated(!showRelated);
  };

  const toggleServices = (): void => {
    setShowServices(!showServices);
  };

  return (
    <footer className="bg-lvl2 border-top mt-5">
      <div className="container">
        {/* First part of the footer, screen view */}
        <div className="d-none d-sm-flex justify-content-between mb-5 pt-5">
          <a href={`/${context.language}/`} className=" pl-0">
            <img
              src={logo}
              alt="SG Markets"
              className="d-none d-sm-block"
            />
          </a>
          <div className="d-flex justify-content-end">
            <BasicDropdown
              label={context.language.toUpperCase()}
              additionalClasses="dropup btn btn-lg btn-link text-black px-0"
              icon="language"
              hideOnClick
            >
              <button
                type="button"
                onClick={() => changeLanguage('en')}
                className="btn-btn-link dropdown-item"
              >
                EN
              </button>
              <button
                type="button"
                onClick={() => changeLanguage('fr')}
                className="btn-btn-link dropdown-item"
              >
                FR
              </button>
            </BasicDropdown>
            {!context.user && (
              <button
                className="btn btn-flat-secondary btn-lg"
                onClick={() => {
                  signIn();
                }}
              >
                <FormattedMessage id="global.signIn" />
              </button>
            )}
          </div>
        </div>
        {/* First part of the footer, mobile view */}
        <div className="d-flex d-sm-none justify-content-around my-4">
          <a href={`/${context.language}/`} className="pt-3">
            <img src={logo} alt="SG Markets" />
          </a>
        </div>

        <div className="row footer-links">
          {/* Services links, screen view */}
          <div className="col-sm-3 d-none d-sm-block">
            <ul className="list-unstyled">
              <li>
                <h5 className="text-muted mb-3">
                  <FormattedMessage id="footer.sgMarketsServices" />
                </h5>
              </li>
              {Object.keys(context.categories)
                .sort(sortCategories())
                .map(category => {
                  return (
                    <li key={`footer-menu-${category}`}>
                      <Link
                        to={`/${context.language}/category/${category}`}
                        className="d-block mb-2 font-weight-bold text-black"
                      >
                        <FormattedMessage id={`category.${category}`} />
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          {/* Services links, mobile view */}
          <div className="col-12 d-sm-none my-3">
            <Dropdown
              isOpen={showServices}
              toggle={() => toggleServices()}
            >
              <DropdownToggle className="btn btn-block btn-discreet-secondary btn-lg">
                <FormattedMessage id="footer.sgMarketsServices" />{' '}
                <i className="icon icon-md">arrow_drop_down</i>
              </DropdownToggle>
              <DropdownMenu>
                {Object.keys(context.categories)
                  .sort(sortCategories())
                  .map(category => {
                    return (
                      <DropdownItem
                        key={`footer-mobile-menu-${category}`}
                      >
                        <Link
                          to={`/${context.language}/category/${category}`}
                          className="d-block mb-2 font-weight-bold text-black"
                        >
                          <FormattedMessage
                            id={`category.${category}`}
                          />
                        </Link>
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </div>

          {/* Related links, screen view */}
          <div className="col-sm-3 d-none d-sm-block">
            <ul className="list-unstyled">
              <li>
                <h5 className="text-muted mb-3">
                  <FormattedMessage id="footer.relatedLinks" />
                </h5>
              </li>
              {relatedLinks.map((link, index) => {
                return (
                  <li key={`related-link-${index}`}>
                    <a
                      href={link.link}
                      className="d-block mb-2 font-weight-bold text-black"
                    >
                      <FormattedMessage id={link.id} />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* Services links, mobile view */}
          <div className="col-12 d-sm-none mb-3">
            <Dropdown
              isOpen={showRelated}
              toggle={() => toggleRelated()}
            >
              <DropdownToggle className="btn btn-block btn-discreet-secondary btn-lg">
                <FormattedMessage id="footer.relatedLinks" />{' '}
                <i className="icon icon-md">arrow_drop_down</i>
              </DropdownToggle>
              <DropdownMenu>
                {relatedLinks.map((link, index) => {
                  return (
                    <DropdownItem key={`related-link-${index}`}>
                      <a
                        href={link.link}
                        className="d-block mb-2 font-weight-bold text-black"
                      >
                        <FormattedMessage id={link.id} />
                      </a>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>

          {/* Important notice */}
          <div className="col-12 col-sm-6">
            <div className="text-muted footer-important-notice">
              <strong className="text-uppercase">
                <FormattedMessage id="footer.importantNotice.title" />
              </strong>
              <FormattedMessage id="footer.importantNotice.text" />
              <a
                href="mailto:sgmarkets@sgcib.com"
                aria-label="Envoyer un email à sgmarkets@sgcib.com via ma boite mail configurée dans le système"
              >
                sgmarkets@sgcib.com
              </a>
              .
            </div>
          </div>
        </div>
      </div>

      <MiniFooter />
    </footer>
  );
};
