import React, { FC, useEffect, useState } from 'react';
import NavbarToggler from 'reactstrap/lib/NavbarToggler';
import Collapse from 'reactstrap/lib/Collapse';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import { LANGUAGE, useGlobalContext } from '../../context/global.context';
import { Link } from 'react-router-dom';
import { AccountCenter } from './AccountCenter';
import { sortCategories, toggleMobileMenu } from '../../services/utils.service';
import { FormattedMessage } from 'react-intl';
import { LanguageSwitcher } from './LanguageSwitcher';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';
import { BasicDropdown } from '../Common/BasicDropdown';
import { signIn } from '../../services/api.service';
import logoEN from '../../assets/images/sg-brand-en.svg';
import logoFR from '../../assets/images/sg-brand-fr.svg';
import logoMini from '../../assets/images/brand-mini.png';

import './Header.scss';

export const HomeHeader: FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [hideMenu, setHideMenu] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);

  const context = useGlobalContext();

  const scrolling: EventListener = () => {
    const top = document.documentElement!.scrollTop;
    if (toggleMobileMenu(lastScroll, hideMenu)) {
      setHideMenu(!hideMenu);
    }
    setLastScroll(top);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrolling);
    return () => window.removeEventListener('scroll', scrolling);
  }, [scrolling]);

  const toggle = (): void => {
    setCollapsed(!collapsed);
  };

  const categories = Object.keys(context.categories).sort(
    sortCategories()
  );
  const moveToQuickLinks = categories.length > 3 ? 'xxl' : 'xl';
  const navbar = (
    <Nav navbar>
      {/*
             The list of categories is moved to a "Quick Links" dropdown, in order to avoid using more than one line.
             It is done like that below 1200px (XL) and also between 1200px (xl) and 1600px (xxl) if there are too many
             categories (more than 3)...
            */}
      <li className="nav-item">
        <BasicDropdown
          label="home.quick-links"
          translate
          additionalClasses={`dropdown text-black d-none d-sm-inline-block d-${moveToQuickLinks}-none`}
          linkAdditionalClasses="nav-link text-secondary display-5 category-link"
        >
          {categories.map(category => {
            return (
              <Link
                to={`/${context.language}/category/${category}`}
                className="dropdown-item"
                key={`nav-quick-link-${category}`}
              >
                <FormattedMessage id={`category.${category}`} />
              </Link>
            );
          })}
        </BasicDropdown>
      </li>

      {categories.map(category => {
        if (!category) {
          return null;
        }
        return (
          <NavItem
            key={`nav-link-${category}`}
            className={`d-md-none d-${moveToQuickLinks}-inline-block`}
          >
            <Link
              to={`/${context.language}/category/${category}`}
              className="nav-link font-weight-bold"
            >
              <span className="display-5 category-link">
                <FormattedMessage id={`category.${category}`} />
              </span>
            </Link>
          </NavItem>
        );
      })}
      {!context.user && (
        <>
          <LanguageSwitcher />

          <li className="nav-item">
            <button
              onClick={() => signIn()}
              className="btn btn-xl nav-link"
            >
              <FormattedMessage id="global.signIn" />
            </button>
          </li>
        </>
      )}
    </Nav>
  );

  return (
    <nav
      className={`navbar navbar-expand-md navbar-light w-100 position-absolute home-header
              ${hideMenu ? 'hide-mobile-menu' : ''}
              ${collapsed ? '' : 'mobile-navbar-opened'}`}
    >
      <NavbarBrand
        href={`/${context.language}/`}
        aria-label={'link to home'}
      >
        <img
          className="d-none d-md-block mr-3 the-future-is-you"
          src={context.language === LANGUAGE.FR ? logoFR : logoEN}
          alt="Societe Generale"
        />
        <img
          className="d-md-none"
          src={logoMini}
          alt="Societe Generale"
        />
      </NavbarBrand>

      {/* Menu dropdown for small screens */}
      <NavbarToggler
        onClick={toggle}
        className="mr-auto d-md-none"
      >
        <WrappedFormattedMessage
          id="global.menu"
          tag="span"
          className="fs-14"
        />
        <i className="icon icon-md text-secondary">arrow_drop_down</i>
      </NavbarToggler>

      {/* Menu for screen, placed before the Account center */}
      <Collapse
        isOpen={!collapsed}
        navbar
        className="d-none d-md-inline-block"
      >
        <ul className="navbar-nav mr-auto " />
        {navbar}
      </Collapse>

      <AccountCenter />

      {/* Menu for mobile, placed after the Account center */}
      <div className="d-md-none" style={{ flexBasis: '100%' }}>
        <Collapse isOpen={!collapsed} navbar>
          <ul className="navbar-nav mr-auto" />
          {navbar}
        </Collapse>
      </div>
    </nav>
  );
};
