import React, { FC } from 'react';
import { useGlobalContext } from '../../context/global.context';
import { IService } from '../../data/data.types';
import { CardsAndListPanel } from './CategoryPanel/CardsAndListPanel';
import { OnlyCardsPanel } from './CategoryPanel/OnlyCardsPanel';
import { OnlyListPanel } from './CategoryPanel/OnlyListPanel';
import { SubCategoryPanel } from './CategoryPanel/SubCategoryPanel/SubCategoryPanel';

export interface ICategoryPanelProps {
  category: string;
}

export const CategoryPanel: FC<ICategoryPanelProps> = ({ category }) => {
  const context = useGlobalContext();
  const services: IService[] = context.getCategoryServices(category);
  const themes: string[] = [];
  const subCategories: { [key: string]: IService[] } = {};

  services.forEach((s: IService) => {
    for (const theme of s.themes) {
      if (themes.indexOf(theme) === -1) {
        themes.push(theme);
      }
    }
    const sub = s.subCategory;
    if (sub) {
      if (!subCategories.hasOwnProperty(sub)) {
        subCategories[sub] = [];
      }
      subCategories[sub].push(s);
    }
  });

  const hasSubCategories = Object.keys(subCategories).length > 0;

  if (hasSubCategories) {
    const sortedThemes = context.sortThemeNames(themes);
    return (
      <SubCategoryPanel
        category={category}
        themes={sortedThemes}
        subCategories={subCategories}
        services={services}
      />
    );
  }

  const displayedServices = services.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  if (displayedServices.length < 4) {
    return <OnlyCardsPanel services={displayedServices} />;
  }

  if (displayedServices.length < 11) {
    return <CardsAndListPanel services={displayedServices} />;
  }

  return <OnlyListPanel services={displayedServices} />;
};
