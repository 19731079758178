import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGlobalContext } from '../../context/global.context';
import { Link } from 'react-router-dom';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';

interface ICategoryDescriptionProps {
  category: string;
}

export const CategoryDescription: FC<ICategoryDescriptionProps> = ({ category }) => {
  const context = useGlobalContext();

  return (
    <div className="category-description">
      <WrappedFormattedMessage
        id={`category.${category}`}
        tag="h3"
        className="display-3"
      />
      <WrappedFormattedMessage id={`home.${category}.subTitle`} tag="h4" />
      <Link
        to={`/${context.language}/category/${category}`}
        className="btn btn-outline-primary btn-lg mt-4"
      >
        <FormattedMessage id="home.service.seeAll" />
      </Link>
    </div>
  );
};
