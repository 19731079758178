import React, { ReactNode, useId } from 'react';
import { useIntlExtended } from 'common/reactIntlExtended';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors, RegisterOptions } from 'react-hook-form';
import { getCustomErrorComponent } from '../errors/customErrorComponent';
import classNames from 'classnames';

interface ICheckboxFormProps<T extends FieldValues> {
    labelId: string;
    name: FieldPath<T>;
    register: UseFormRegister<T>;
    registerOptions?: RegisterOptions<T>;
    errors: FieldErrors<T>;
    disabled?: boolean;
}

export const CheckboxForm = <T extends FieldValues>({ labelId, name, register, registerOptions, errors, disabled }: ICheckboxFormProps<T>): ReactNode => {
    const { t } = useIntlExtended();
    const inputId = useId();

    const error = errors[name]?.message;
    const inputClassName = classNames('custom-control-input', {
        'is-invalid': error,
    });

    return <div className="custom-control custom-checkbox mb-4">
        <input
            id={inputId}
            type="checkbox"
            className={inputClassName}
            {...register(name, registerOptions)}
            disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={inputId}>
            {t(labelId)}
        </label>
        {typeof error === 'string' && <div className="invalid-feedback">
            {getCustomErrorComponent(error) ?? t(`${error}`)}
        </div>}
    </div>;
};
