import React, { FC } from 'react';
import { DEFAULT_SGM_SERVICE_NAME } from '../models';

export interface IService {
    name: string;
    category: string;
    key: string;
    url: string;
}

interface ISideComponentProps {
    service?: IService;
}

const defaultService: IService = {
    key: '',
    name: DEFAULT_SGM_SERVICE_NAME,
    category: 'Markets',
    url: 'https://info.sgmarkets.com',
};

export const SideComponent: FC<ISideComponentProps> = ({ service }) => {
    service ??= defaultService;
    return <aside className="aside d-none d-xl-block col-lg-3 position-relative">
        <div className="aside-inner position-fixed p-5">
            <a
                href={service.url}
                className="left-title d-block border-top position-absolute pt-4"
            >
                <i className="icon position-absolute icon-lg text-light">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                    </svg>
                </i>
                <span className="h2 d-block w-75">{service.name}</span>
                <span className="d-block">{service.category}</span>
            </a>
        </div>
    </aside>;
};
