
import { useCallback, useMemo } from 'react';
import { useIntl, IntlShape, PrimitiveType } from 'react-intl';

type IntlTranslate = (id: string, values?: Record<string, PrimitiveType>) => string;

interface IntlShapeExtended extends IntlShape {
    t: IntlTranslate;
}

export const useIntlExtended = (): IntlShapeExtended => {
    const intl = useIntl();
    const t: IntlTranslate = useCallback((id, values?) => intl.formatMessage({ id }, values), [intl]);
    return useMemo(() => ({ ...intl, t }), [intl, t]);
};
