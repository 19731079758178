import * as React from 'react';
import { IService } from '../../../data/data.types';
import { ServiceCard } from '../../Common/ServiceCard';

export interface ICardsPanelProps {
  services: IService[];
}

export const OnlyCardsPanel = (props: ICardsPanelProps) => (
  <>
    {/* Large screens */}
    <div className="row d-none d-md-flex lx-3">
      {
        props.services.map(service => (
          <div key={`card-list-${service.serviceKey}`} className="col-sm-4">
            <ServiceCard service={service} raising={false} />
          </div>
        ))
      }
    </div>

    {/* Mobile view */}
    <div className="row responsive-slider flex-nowrap d-md-none">
      {
        props.services.map(service => (
          <div key={`card-list-mobile-${service.serviceKey}`} className="responsive-slider-card ml-3">
            <ServiceCard service={service} raising={false} />
          </div>
        ))
      }
    </div>
  </>
);
