import React, { ReactNode, PropsWithChildren, useId } from 'react';
import { useIntlExtended } from 'common/reactIntlExtended';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors } from 'react-hook-form';
import classNames from 'classnames';
import { getCustomErrorComponent } from '../errors/customErrorComponent';

interface ISelectFormProps<T extends FieldValues> extends PropsWithChildren {
    labelId: string;
    name: FieldPath<T>;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    disabled?: boolean;
}

export const SelectForm = <T extends FieldValues>({ labelId, name, register, errors, disabled, children }: ISelectFormProps<T>): ReactNode => {
    const { t } = useIntlExtended();
    const inputId = useId();

    const error = errors[name]?.message;
    const selectClassName = classNames('form-control form-control-xl ', {
        'is-invalid': error,
    });

    return <div className="form-group mb-4">
        <label className="mb-1" htmlFor={inputId}>
            {t(labelId)}
        </label>
        <select
            className={selectClassName}
            id={inputId}
            disabled={disabled}
            {...register(name)}
        >
            {children}
        </select>
        {typeof error === 'string' && <div className="invalid-feedback">
            {getCustomErrorComponent(error) ?? t(`${error}`)}
        </div>}
    </div>;
};
