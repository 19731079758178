import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGlobalContext } from '../../context/global.context';
import { CategoryDescription } from './CategoryDescription';
import { CategoryPanel } from './CategoryPanel';
import { HomeHeader } from '../Header/HomeHeader';
import { Search } from '../Search/Search';
import { AwardList } from '../Awards/AwardsList';
import { ResponsiveText } from '../Common/ResponsiveText';
import { VideoPlayer } from '../Common/VideoPlayer';
import { HomeFooter } from '../Footer/HomeFooter';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';
import { getPageMetadata, sortCategories } from '../../services/utils.service';
import { IAward } from '../../data/data.types';
import { HelpCenter } from '../HelpCenter/HelpCenter';
import { CategoryHomeCard } from './CategoryHomeCard';
import { HOMEPAGE_VIDEO_ID } from '../../common/constants';
import DocumentMeta from 'react-document-meta';
import logo from '../../assets/images/sgm-logo.svg';

import './HomePage.scss';

export const HomePage: FC = () => {
  const { formatMessage } = useIntl();
  const context = useGlobalContext();
  const [video, setVideo] = useState(false);

  const startVideo = (): void => {
    setVideo(true);
  };

  const stopVideo = (): void => {
    setVideo(false);
  };

  const sortedCategories = Object.keys(context.categories).sort(
    sortCategories()
  );

  const awards = context.awards.filter((a: IAward) => a.homepage);
  const meta = getPageMetadata();

  return (
    <DocumentMeta {...meta}>
      <HomeHeader />
      <div className="info-content homepage">
        <div className="home-image text-center align-self-center">
          <div className="container">
            <img
              src={logo}
              alt="SG Markets"
              className="sg-markets-logo"
            />
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">
              <div className="home-title mb-4">
                <ResponsiveText
                  tagName="h1"
                  text="home.title"
                  translate
                  mobileClasses="display-3"
                  screenClasses="display-2"
                />
              </div>

              <a
                href="#introduction"
                className="btn btn-primary btn-xl mr-2"
              >
                <FormattedMessage id="home.discover" />
              </a>
              <button
                className="btn btn-flat-secondary btn-xl btn-icon-text"
                onClick={startVideo}
              >
                <i className="icon">play_circle_filled</i>
                {formatMessage({ id: 'home.video' })}
              </button>
              <VideoPlayer
                videoId={HOMEPAGE_VIDEO_ID}
                startVideo={video}
                onStop={stopVideo}
              />
              <div id="introduction">
                <ResponsiveText
                  tagName="h2"
                  text="home.intro.title"
                  translate
                  className="home-introduction font-weight-medium"
                  mobileClasses="display-4"
                  screenClasses="display-3"
                />
                <WrappedFormattedMessage
                  id="home.intro.text"
                  tag="p"
                  className="mb-4"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-3 mt-4 mb-5">
              <Search header={false} />
            </div>
          </div>
        </div>

        {/* Display category cards */}
        <div className="container">
          <div className="row">
            {sortedCategories.map(category => {
              const breakpoint = sortedCategories.length === 4 ? 3 : 4;
              return (
                <CategoryHomeCard
                  category={category}
                  active={true}
                  breakpoint={breakpoint}
                  key={`category-card-${category}`}
                />
              );
            })}
          </div>
        </div>

        {sortedCategories.map((category, index) => (
          <div
            key={category}
            className={`category-wrapper ${index % 2 === 0 ? 'even' : 'odd'} category-${category}`}
          >
            <div className="category-margin" />
            <CategoryDescription category={category} />
            <div className="py-5 category-services">
              <CategoryPanel category={category} />
            </div>
            <div className="category-margin" />
          </div>
        ))}

        <div className="container-fluid">
          <h2 className="achievements-title w-100 text-center mb-5 mt-5">
            <FormattedMessage id="home.achievements" />
          </h2>
        </div>
        <div className="container">
          <AwardList awards={awards} />
        </div>
      </div>

      <HelpCenter />
      <HomeFooter />
    </DocumentMeta>
  );
};
