import React, { FC, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { useIntlExtended } from 'common/reactIntlExtended';
import { parseQueryString } from 'common/utils';
import { useGlobalContext } from 'context/global.context';
import { logger } from 'services/utils.service';
import { MainComponent, SuccessComponent } from './RequestAccessComponent';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ServiceDetailsFormPart } from './form/ServiceDetailsFormPart';
import { InputForm } from './inputs';
import { IRequestDemoContent, PersonalUserEmailRegex, RepresentativeEmailRegex, DEFAULT_SGM_SERVICE_NAME, FRANFINANCE_SERVICE_KEY, SHAREINBOX_SERVICE_KEY } from './models';
import { shareinboxCustomError } from './errors/customErrorComponent';

const apiRequestAccessBase = process.env.REACT_APP_API_REQUEST_ACCESS_BASE;

interface IRequestDemoFormProps {
    initialServiceKey?: string;
    initialServiceName?: string;
}

const schema = () => yup
    .object()
    .shape({
        displayServiceDetails: yup.boolean().required(),

        serviceKey: yup.string()
            .when('displayServiceDetails', {
                is: true,
                then: (schema) => schema.notRequired(),
                otherwise: (schema) => schema
                    .required('request-access.required')
                    .test('shareinbox', shareinboxCustomError, x => x !== SHAREINBOX_SERVICE_KEY),
            }),

        serviceDetails: yup.string()
            .when('displayServiceDetails', {
                is: true,
                then: (schema) => schema.required('request-access.required'),
                otherwise: (schema) => schema.notRequired(),
            }),

        companyName: yup.string().required('request-access.required'),

        firstName: yup.string().required('request-access.required').max(50, 'request-access.too-long'),
        lastName: yup.string().required('request-access.required').max(50, 'request-access.too-long'),

        email: yup.string()
            .email('request-access.form.details.email.error')
            .required('request-access.required')
            .max(100, 'request-access.too-long')
            .when('serviceKey', {
                is: (serviceKey: string) => serviceKey !== FRANFINANCE_SERVICE_KEY,
                then: (schema) => schema.matches(PersonalUserEmailRegex, { excludeEmptyString: true, message: 'request-access.form.details.email.error' }),
            }),

        professionalPhoneNumber: yup.string(),

        representativeEmail: yup.string()
            .email('request-access.form.representative.email.error')
            .matches(RepresentativeEmailRegex, { excludeEmptyString: true, message: 'request-access.form.representative.email.error' })
            .max(100, 'request-access.too-long'),
    });

export const RequestDemoForm: FC<IRequestDemoFormProps> = ({ initialServiceKey, initialServiceName }) => {
    const { t } = useIntlExtended();
    const context = useGlobalContext();
    const [success, setSuccess] = useState(false);
    const [apiErrors, setApiErrors] = useState<string[]>([]);
    const hasInitialService = !!initialServiceKey;

    const formMethods = useForm<IRequestDemoContent>({
        mode: 'onBlur',
        resolver: yupResolver(schema()),
        defaultValues: {
            serviceKey: initialServiceKey,
            displayServiceDetails: false,
        },
    });

    const { register, formState: { errors, isSubmitting } } = formMethods;

    const handleSubmit: SubmitHandler<IRequestDemoContent> = (data) => {
        setApiErrors([]);
        const parsedQueryString = parseQueryString(location.search);
        const config = {
            url: `${apiRequestAccessBase}/requests/demo-form`,
            method: 'POST',
            data: {
                serviceKey: data.serviceKey || '__EMPTY__',
                details: data.displayServiceDetails ? data.serviceDetails : undefined,
                companyName: data.companyName,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                professionalPhoneNumber: data.professionalPhoneNumber,
                representativeEmail: data.representativeEmail,

                requestFormLanguage: context.language,
                returnUrl: parsedQueryString.returnurl || '',
                sourceUrl: parsedQueryString.sourceurl || '',
                sourceContext: parsedQueryString.sourcecontext || '',
            },
            validateStatus: () => true,
            timeout: 15000,
            headers: {},
        };
        return axios(config)
            .then(({ data, status }) => {
                if (status === 200) {
                    setSuccess(true);
                    window.scroll(0, 0);
                } else if (status === 400) {
                    logger.warn('Bad request error on form', JSON.stringify(data));
                    setApiErrors(formatApiBadRequest(data));
                } else {
                    logger.error('Unknown error on form', JSON.stringify(data));
                    setApiErrors([t('request-access.form.submit.error')]);
                }
            })
            .catch(error => {
                logger.error('Not able to fill form', JSON.stringify(error));
                setApiErrors([t('request-access.form.submit.error')]);
            });
    };

    if (success) {
        return <MainComponent>
            <SuccessComponent />
        </MainComponent>;
    }

    return (
        <MainComponent>
            <h1 className="display-3 mb-1">{t('request-access.request-demo')}</h1>
            <h2 className="h4 spacing-mb-2 text-secondary">
                {t('request-access.request-demo.fill', { serviceName: initialServiceName ?? DEFAULT_SGM_SERVICE_NAME })}
            </h2>
            <div className="position-relative spacing-mb-5">
                <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                    <FormProvider {...formMethods} >
                        {!hasInitialService && <ServiceDetailsFormPart noTitle />}

                        <InputForm
                            labelId="request-access.form.company.name"
                            placeholderId="request-access.form.company.name.placeholder"
                            register={register}
                            errors={errors}
                            name="companyName"
                            disabled={isSubmitting}
                        />

                        <div className="form-group mb-0">
                            <label className="mb-1">
                                {t('request-access.form.details.name')}
                            </label>
                            <div className="d-flex d-row gap-3">
                                <InputForm
                                    placeholderId="request-access.form.details.name.firstname.placeholder"
                                    register={register}
                                    errors={errors}
                                    name="firstName"
                                    disabled={isSubmitting}
                                />
                                <InputForm
                                    placeholderId="request-access.form.details.name.lastname.placeholder"
                                    register={register}
                                    errors={errors}
                                    name="lastName"
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <InputForm
                            type="email"
                            labelId="request-access.form.details.email"
                            placeholderId="request-access.form.details.email.placeholder"
                            register={register}
                            errors={errors}
                            name="email"
                            disabled={isSubmitting}
                        />

                        <InputForm
                            labelId="request-access.form.company.professionalPhoneNumber.optional"
                            placeholderId="request-access.form.company.professionalPhoneNumber.placeholder"
                            register={register}
                            errors={errors}
                            name="professionalPhoneNumber"
                            disabled={isSubmitting}
                        />

                        <InputForm
                            type="email"
                            labelId="request-access.form.representative.email.optional"
                            placeholderId="request-access.form.representative.email.placeholder"
                            register={register}
                            errors={errors}
                            name="representativeEmail"
                            disabled={isSubmitting}
                        />

                        <button
                            type="submit"
                            className="btn btn-xl btn-block btn-socgen spacing-mt-2"
                            disabled={isSubmitting}
                        >
                            {t('request-access.form.submit-demo')}
                        </button>

                        {apiErrors?.length > 0 && <>
                            <div className="mt-4 alert alert-danger alert-discreet-danger">
                                {apiErrors.map((x, i) => <div key={i}>{x}</div>)}
                            </div>
                        </>}
                    </FormProvider>
                </form>
            </div>
        </MainComponent >
    );
};

const formatApiBadRequest = (data: any): string[] => {
    return Object.keys(data.errors || {}).map(x => data.errors[x]);
};
