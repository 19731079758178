import React, { FC } from 'react';
import { BasicDropdown } from '../Common/BasicDropdown';
import { useGlobalContext } from '../../context/global.context';
import { FormattedMessage } from 'react-intl';

export const LanguageSwitcher: FC = () => {
  const context = useGlobalContext();
  const changeLanguage = (newLanguage: string): void => {
    context.changeLanguage(newLanguage);
  };

  const language = context.language;
  return (
    <>
      <BasicDropdown
        label={context.language.toUpperCase()}
        additionalClasses="nav-item dropdown text-black d-none d-sm-inline-block"
        linkAdditionalClasses="nav-link text-secondary"
        icon="language"
        hideOnClick
      >
        <button
          type="button"
          onClick={() => changeLanguage('en')}
          className="btn btn-link dropdown-item"
        >
          EN
        </button>
        <button
          type="button"
          onClick={() => changeLanguage('fr')}
          className="btn btn-link dropdown-item"
        >
          FR
        </button>
      </BasicDropdown>
      <li className="nav-item d-sm-none">
        <button
          type="button"
          className="btn btn-link nav-link text-secondary"
          onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')}
        >
          <FormattedMessage id="global.switch-language" />
        </button>
      </li>
    </>
  );
};
