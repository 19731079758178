export const INSIGHT_SERVICE_KEY = 'SGMARKETS';
export const FRANFINANCE_SERVICE_KEY = 'SGM_FRANFINANCE';
export const DEFAULT_SGM_SERVICE_NAME = 'SGMarkets';
export const SHAREINBOX_SERVICE_KEY = 'SGM_SHAREINBOX';
export const SHAREINBOX_SERVICE_NAME = 'Sharinbox';

export interface BaseRequestFormContent {
    serviceKey?: string;
    displayServiceDetails: boolean;
    serviceDetails?: string;

    companyName: string;

    firstName: string;
    lastName: string;
    email: string;

    professionalPhoneNumber?: string;
    representativeEmail?: string;
}

export interface IRequestDemoContent extends BaseRequestFormContent {
}

export interface IRequestAccessContent extends BaseRequestFormContent {
    country: string;
    companyAddress: string;
    siren?: string;
    clientReference?: string;

    jobTitle: string;

    isMifid2Eligible?: string;

    hasNotRepresentativeEmail: boolean;
    representativeDetails?: string;
}

export const PersonalUserEmailRegex: RegExp = /\w+([-+.]\w+)*@(?!aol)(?!msn)(?!hotmail)(?!yahoo)(?!gmail)(?!reuters)(?!bloomberg)\w+([-.]\w+)*\.\w+([-.]\w+)*/;
export const RepresentativeEmailRegex: RegExp = /\w+([-+.]\w+)*@(((sgcib|lyxor|bernsteinsg)\.com)|(sgef(\.pl|\.nl|\.be|\.lu|\.uk|\.it|\.es))|(sgss\.)?(socgen\.com(\.cn)?))/;
