import React, { FC, useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGlobalContext } from '../../context/global.context';
import { Header } from '../Header/Header';
import { useLocation } from 'react-router';
import {
  getPageMetadata,
  getServicesThemes,
  logger,
  sortServices,
  splitServicesByThemes,
} from '../../services/utils.service';
import { Link } from 'react-router-dom';
import { MiniFooter } from '../Header/MiniFooter';
import { HighlightedThemes } from './HighlightedThemes';
import { HelpCenter } from '../HelpCenter/HelpCenter';
import { ServiceCardsList } from './ServiceCardsList';
import { ResponsiveText } from '../Common/ResponsiveText';
import { CATEGORY_PAGE_SERVICES_CARDS_PER_THEME } from '../../common/constants';

import './CategoryPage.scss';

export const CategoryPage: FC = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const context = useGlobalContext();
  const [category, setCategory] = useState('');

  useEffect(() => {
    const path = location.pathname.toLowerCase().split('/');
    setCategory(path[path.length - 1]);
  }, [location.pathname]);

  if (!category) {
    logger.warn(`Category "${category}" does not exist.`);
    return null;
  }

  const services = context.getCategoryServices(category);
  if (!services) {
    logger.warn(`Category "${category}" has no associated services.`);
    return null;
  }
  // Display only markets themes on category page
  const themes: string[] =
    category === 'markets'
      ? context.sortThemeNames(getServicesThemes(services))
      : [];
  const servicesPerTheme = splitServicesByThemes(services);
  const meta = getPageMetadata(
    formatMessage(
      { id: 'global.title.category' },
      {
        category: formatMessage({
          id: `category.${category}`,
        }),
      }
    )
  );

  return (
    <DocumentMeta {...meta}>
      <header>
        <Header />
      </header>
      <main className="info-content category-page">
        <div className="container-fluid px-0">
          <div className="container">
            <ResponsiveText
              text={`category.${category}`}
              translate
              mobileClasses="display-3 pt-3 mb-4"
              screenClasses="display-3 mt-5 mb-4"
              tagName="h1"
            />

            <HighlightedThemes themes={themes} />
          </div>
        </div>

        <div className="container-fluid px-0 mb-3">
          {themes.map((theme: string) => {
            const title = (
              <>
                <FormattedMessage id={`theme.${theme}`}>
                  {(txt: any) => (
                    <>
                      <h1 className="d-none d-sm-block">{txt}</h1>
                      <h2 className="d-sm-none">{txt}</h2>
                    </>
                  )}
                </FormattedMessage>
                <Link
                  to={`/${context.language}/theme/${theme}`}
                  className="text-secondary pt-2"
                >
                  <span className="d-none d-sm-block">
                    <FormattedMessage
                      id="theme.all.services"
                      values={{
                        theme: (
                          <FormattedMessage id={`theme.${theme}`} />
                        ),
                      }}
                    />
                  </span>
                  <span className="d-sm-none">
                    <FormattedMessage id="theme.all.see" />
                  </span>
                </Link>
              </>
            );

            return (
              <div
                className="container"
                key={`category-theme-${theme}`}
              >
                <div className="mt-5 mb-3">
                  <div className="d-inline-flex justify-content-between w-100">
                    {title}
                  </div>
                </div>

                <ServiceCardsList
                  services={servicesPerTheme[theme]
                    .sort(sortServices)
                    .slice(0, CATEGORY_PAGE_SERVICES_CARDS_PER_THEME)}
                />
              </div>
            );
          })}
          {themes.length === 0 && (
            <div className="container">
              <ServiceCardsList services={services} />
            </div>
          )}
        </div>
      </main>

      <HelpCenter />
      <footer>
        <MiniFooter />
      </footer>
    </DocumentMeta>
  );
};

