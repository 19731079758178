import * as React from 'react';
import { IService } from '../../../../data/data.types';
import { CategoryServicesList } from '../../CategoryServicesList';
import { HeadSubCategoryPanel } from './HeadSubCategoryPanel';

interface ISubCategoryPanelProps {
  category: string;
  themes: string[];
  services: IService[];
  subCategories: { [key: string]: IService[] };
}

interface ISubCategoryPanelState {
  activeTheme: string | null;
}

export class SubCategoryPanel extends React.Component<
  ISubCategoryPanelProps,
  ISubCategoryPanelState
> {
  constructor(props: ISubCategoryPanelProps) {
    super(props);
    this.state = {
      activeTheme: null,
    };
  }

  handleActiveThemeChange = (activeTheme: string) =>
    this.setState({ activeTheme: activeTheme === 'all' ? null : activeTheme });

  render() {
    const displayedServices = this.props.services
      .filter(
        s =>
          !this.state.activeTheme ||
          (s.themes && s.themes.indexOf(this.state.activeTheme) !== -1)
      )
      .map(s => s.serviceKey);

    return (
      <div className="column">
        {/* Navigation between themes */}
        <ul className="nav mb-4 display-5">
          <HeadSubCategoryPanel
            activeTheme={this.state.activeTheme}
            onActiveThemeChange={this.handleActiveThemeChange}
            category={this.props.category}
            themes={this.props.themes}
          />
        </ul>
        {/* Services list (by cards if no subCategories, or split by subCategories) */}
        <div className="fullpage-horizontal-scroll">
          <CategoryServicesList
            category={this.props.category}
            displayedServices={displayedServices}
            subCategories={this.props.subCategories}
            themes={this.props.themes}
          />
        </div>
      </div>
    );
  }
}
