import React, { FC } from 'react';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/global.context';
import { ResponsiveText } from '../Common/ResponsiveText';

export interface ICategoryHomeCardProps {
  active: boolean;
  breakpoint: number;
  category: string;
}

export const CategoryHomeCard: FC<ICategoryHomeCardProps> = ({ active, breakpoint, category }) => {
  const context = useGlobalContext();
  const classes = `col-6 col-md-${breakpoint} my-3 text-primary`;

  const content =
    <div className={`card card-bordering category-card h-100 ${!active && 'd-none d-sm-block unavailable-category'}`}>
      <div className={`mx-auto d-block category-image ${category}`} />
      <div className="card-body pt-0 homepage-category-title">
        <ResponsiveText
          text={`category.${category}`} translate
          mobileClasses="font-weight-bold card-title my-3"
          screenClasses="h1 font-weight-bold card-title my-3"
          tagName="h2" />
        <WrappedFormattedMessage
          id={active ? `home.${category}.description` : 'home.coming-soon'}
          tag="p"
          className="card-text d-none d-sm-block" />
      </div>
    </div>;

  return (
    active ?
      <Link to={`/${context.language}/category/${category}`}
        className={classes} style={{ textDecoration: 'none', fontWeight: 'inherit' }}>
        {content}
      </Link>
      :
      <span className={classes}>
        {content}
      </span>
  );
};
