import React, { FC } from 'react';
import { useGlobalContext } from '../../context/global.context';
import { MiniFooter } from '../Header/MiniFooter';
import { Header } from '../Header/Header';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';

/**
 * The application builds the routes dynamically, once it gets the services data from the API. When the application
 * starts, there is no data, so we do not know which routes are valid and which are not.
 * This component handle all the routes - except for the EN & FR homepages - until the data is resolved. Then, this
 * component is not used anymore.
 */
export const WaitForDataComponent: FC = () => {
  const { dataError } = useGlobalContext();
  return (
    <>
      <header>
        <Header highlightedCategory="markets" />
      </header>

      <main className="container-fluid info-content d-flex justify-content-center align-items-center pb-5">
        {dataError ? (
          <div className="text-center pb-5">
            <i className="icon icon-xxl text-secondary mb-4">cancel</i>
            <WrappedFormattedMessage id="global.loadingError" tag="h1" />
          </div>
        ) : (
          <div className="loader">
            <WrappedFormattedMessage id="global.loading" tag="h1" />
          </div>
        )}
      </main>
      <footer>
        <MiniFooter />
      </footer>
    </>
  );
};
