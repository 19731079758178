import { MouseEvent, KeyboardEvent } from 'react';

export const parseQueryString = (path: string): { [key: string]: string } =>
  path
    .slice(1)
    .split('&')
    .reduce((car, cur) => {
      const [key, value] = cur.split('=');
      return { ...car, [key.toLowerCase()]: value };
    }, {});

export const handleA11y = (fc: () => void) => (evt: MouseEvent | KeyboardEvent) => {
  if (evt.type === 'click' || evt.type === 'keyup' && (evt as KeyboardEvent).key === 'Enter') {
    fc();
  }
};
