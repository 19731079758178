import React, { FC } from 'react';

export interface IResponsiveComponentProps {
  component?: JSX.Element | JSX.Element[];
  componentForMobile?: JSX.Element | JSX.Element[];
  componentForScreen?: JSX.Element | JSX.Element[];
  mobileClasses?: string;
  screenClasses?: string;
}

/**
 * This class creates a component that will split the component into 2 views:
 *   - for "screens", it simply displays it in a row;
 *   - for "mobile", it displays it in a row with horizontal scroll.
 */
export const ResponsiveComponent: FC<IResponsiveComponentProps> = ({
  component, componentForMobile, componentForScreen, mobileClasses, screenClasses,
}) => {
  return (
    <>
      {/* Component for large screens */}
      <div className={`row d-none d-sm-flex ${screenClasses || ''}`}>
        {component || componentForScreen}
      </div>

      {/* Component for mobile view */}
      <div className={`row d-sm-none ${mobileClasses || ''}`}>
        <div style={{ overflowY: 'hidden' }} className="mobile-horizontal-scroll w-100">
          <div className="d-inline-flex w-100">
            {component || componentForMobile}
          </div>
        </div>
      </div>
    </>
  );
};
