import React, { FC } from 'react';
import { IAward } from '../../data/data.types';
import { AwardCard } from './AwardCard';
import { ResponsiveComponent } from '../Common/ResponsiveComponent';

export interface IAwardListProps {
  awards: IAward[];
}

export const AwardList: FC<IAwardListProps> = ({ awards }) => {
  const col = awards.length === 1 ? '12' : awards.length === 2 ? '6' : '4';
  const cards = awards.map((award: IAward) =>
    <div className={`col-${col} col-md-3 mb-3`} key={award.id}>
      <AwardCard award={award} />
    </div>
  );
  return <ResponsiveComponent component={cards} />;
};
