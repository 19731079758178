import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './components/App/App';

// Polyfills
import 'core-js/es/array';

import '@sg-bootstrap/icons/dist/index.css';
import '@sg-bootstrap/core/build/sg-bootstrap-standard.css';

import 'pixeden-stroke-7-icon/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css';

const container = document.getElementById('info-root');
const root = createRoot(container!);
root.render(<App />);
