import React, { ReactNode, useId } from 'react';
import { useIntlExtended } from 'common/reactIntlExtended';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors, UseFormWatch } from 'react-hook-form';
import { getCustomErrorComponent } from '../errors/customErrorComponent';
import classNames from 'classnames';

import './RadioForm.scss';

interface IRadioFormOption {
    value: string;
    labelId: string;
}

interface IRadioFormProps<T extends FieldValues> {
    name: FieldPath<T>;
    register: UseFormRegister<T>;
    watch: UseFormWatch<T>;
    errors: FieldErrors<T>;
    disabled?: boolean;
    options: IRadioFormOption[];
}

export const RadioForm = <T extends FieldValues>({ name, options, register, watch, errors, disabled }: IRadioFormProps<T>): ReactNode => {
    const { t } = useIntlExtended();
    const error = errors[name]?.message;

    const groupClassName = classNames('btn-group btn-group-toggle w-100 form-control-radio', {
        'is-invalid': error,
    });

    return <div className="form-group mb-4 radio-form">
        <div
            className={groupClassName}
            data-toggle="buttons"
            role="group"
            aria-label="toggle buttons single select"
        >
            {options.map(x => <RadioFormOption
                key={x.value}
                name={name}
                register={register}
                watch={watch}
                disabled={disabled}
                labelId={x.labelId}
                value={x.value}
            />)}
        </div>
        {typeof error === 'string' && <div className="invalid-feedback">
            {getCustomErrorComponent(error) ?? t(`${error}`)}
        </div>}
    </div>;
};

interface IRadioFormOptionProps<T extends FieldValues> {
    name: FieldPath<T>;
    register: UseFormRegister<T>;
    watch: UseFormWatch<T>;
    disabled?: boolean;
    labelId: string;
    value: string;
}

const RadioFormOption = <T extends FieldValues>({ name, value, labelId, register, watch, disabled }: IRadioFormOptionProps<T>): ReactNode => {
    const { t } = useIntlExtended();
    const inputId = useId();
    const currentValue = watch(name);

    const labelClassName = classNames('btn btn-xl btn-toggle-primary', {
        'active': value === currentValue,
        'disabled': disabled,
    });

    return <>
        <label className={labelClassName}>
            <input
                id={inputId}
                type="radio"
                autoComplete="off"
                disabled={disabled}
                {...register(name)}
                value={value}
            />
            {t(labelId)}
        </label>
    </>;
};
