import React, { FC, useState, PropsWithChildren, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

export interface IBasicDropdownState {
  visible: boolean;
}

export interface IBasicDropdownProps extends PropsWithChildren {
  additionalClasses?: string;
  hideOnClick?: boolean;
  icon?: string;
  label: string;
  translate?: boolean;
  linkAdditionalClasses?: string;
  onVisibilityChanged?: (visible: boolean) => void;
}

/**
 * This is a basic implementation of the dropdown Bootstrap component, which does not require any additional libraries.
 */
export const BasicDropdown: FC<IBasicDropdownProps> = (props) => {
  const { formatMessage } = useIntl();
  const elementRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  const {
    additionalClasses,
    linkAdditionalClasses,
    icon,
    label,
    translate,
    hideOnClick,
    children,
    onVisibilityChanged,
  } = props;

  const switchVisibility = (event?: React.MouseEvent) => {
    if (onVisibilityChanged) {
      onVisibilityChanged(!visible);
    }
    setVisible(!visible);

    if (event) {
      event.stopPropagation();
    }
  };

  /**
   * Handle the click when the dropdown menu is visible. Check if the click is made outside the menu to close it.
   * @param {Event} event
   */
  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (hideOnClick
        || elementRef.current && !elementRef.current.contains(event.target as Node)) {
        switchVisibility();
      }
    };

    if (visible) {
      // Menu is visible, we add the event listener on click / touchend.
      document.addEventListener('click', handleOutsideClick, false);
      document.addEventListener('touchend', handleOutsideClick, false);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
      document.removeEventListener('touchend', handleOutsideClick, false);
    };
  }, [elementRef, visible, hideOnClick, switchVisibility]);

  const labelText = translate ? formatMessage({ id: label }) : label;

  return (
    <div
      className={`pointer ${additionalClasses || ''}`}
      ref={elementRef}
    >
      <button
        type="button"
        onClick={switchVisibility}
        style={{ cursor: 'pointer' }}
        className={`btn btn-link dropdown-toggle ${linkAdditionalClasses || ''}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {icon && <i className="icon icon-prepend mr-0">{icon}</i>}
        <span className="text-primary">{labelText}</span>
      </button>
      <div
        className={`dropdown-menu dropdown-menu-right ${visible && 'visible show'}`}
        style={{ zIndex: 1024 }}
        aria-label={labelText}
      >
        {children}
      </div>
    </div>
  );
};
