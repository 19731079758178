import React from 'react';
import { ShareinboxHelpFormPart } from '../form/ShareinboxHelpFormPart';

const customErrorKey = 'custom-error-component';
export const shareinboxCustomError = `${customErrorKey}.shareinbox`;

export const getCustomErrorComponent = (name: string): JSX.Element | null => {
    switch (name) {
        case shareinboxCustomError:
            return <ShareinboxHelpFormPart />;
        default:
            return null;
    }
};
