import { loadScript } from '../../services/loadScript';

export function displayCookieConsents() {
  const widget = getFooterWidget();
  if (widget && (widget as any).displayUserConsentPopup) {
    (widget as any).displayUserConsentPopup();
  }
}

export function getFooterWidget() {
  return document.getElementById('sgwt-mini-footer');
}

export function addUserConsentSettingsCallback(callback: (data: any) => void) {
  const widget = getFooterWidget();
  if (!widget) {
    return;
  }
  (widget as any).addEventListener(
    'sgwt-mini-footer--current-user-consent',
    callback
  );
  (widget as any).addEventListener(
    'sgwt-mini-footer--user-consent-changed',
    callback
  );
}

export function removeUserConsentSettingsCallback(
  callback: (data: any) => void
) {
  const widget = getFooterWidget();
  if (!widget) {
    return;
  }
  (widget as any).removeEventListener(
    'sgwt-mini-footer--current-user-consent',
    callback
  );
  (widget as any).removeEventListener(
    'sgwt-mini-footer--user-consent-changed',
    callback
  );
}

export function getUserConsentOnQumu(): Promise<boolean> {
  const widget = getFooterWidget();
  if (widget && (widget as any).getUserConsentOnQumu) {
    return (widget as any).getUserConsentOnQumu();
  }
  return new Promise<boolean>((resolve) => {
    resolve(false);
  });
}

export function loadQumuScript(): Promise<void> {
  return loadScript(
    'qumu',
    'https://socgen.qumucloud.com/widgets/application.js'
  );
}
