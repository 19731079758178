import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntlExtended } from 'common/reactIntlExtended';
import { CheckboxForm, InputForm, TextareaForm } from '../inputs';
import { IRequestAccessContent } from '../models';

type RepresentativeFormContext = Pick<IRequestAccessContent, 'representativeEmail' | 'hasNotRepresentativeEmail' | 'representativeDetails'>;

interface IRepresentativeFormPartProps {
    titleOrder: number;
}

export const RepresentativeFormPart: FC<IRepresentativeFormPartProps> = ({ titleOrder }) => {
    const { t } = useIntlExtended();
    const { register, watch, resetField, formState: { errors, isSubmitting } } = useFormContext<RepresentativeFormContext>();
    const hasNotRepresentativeEmail = watch('hasNotRepresentativeEmail');

    return <>
        <p className="text-xlarge font-weight-medium mb-4 spacing-mt-2">
            {t('request-access.form.representative', { count: titleOrder })}
        </p>
        <InputForm
            type="email"
            labelId="request-access.form.representative.email"
            placeholderId="request-access.form.representative.email.placeholder"
            register={register}
            errors={errors}
            name="representativeEmail"
            disabled={hasNotRepresentativeEmail || isSubmitting}
        />
        <CheckboxForm
            labelId="request-access.form.representative.email.nothave"
            register={register}
            registerOptions={{
                onChange: () => {
                    resetField('representativeEmail');
                    resetField('representativeDetails');
                },
            }}
            errors={errors}
            name="hasNotRepresentativeEmail"
            disabled={isSubmitting}
        />
        {hasNotRepresentativeEmail && <TextareaForm
            labelId="request-access.form.representative.details"
            placeholderId="request-access.form.representative.details.placeholder"
            register={register}
            errors={errors}
            name="representativeDetails"
            rows={2}
            disabled={isSubmitting}
        />}
    </>;
};
