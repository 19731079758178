import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useGlobalContext } from '../../context/global.context';
import { Link } from 'react-router-dom';
import { IService } from '../../data/data.types';
import { servicePath, shareServiceLink } from '../../services/utils.service';
import { trackEvent } from '../../services/piwik.service';
import { WrappedFormattedMessage } from './WrappedFormattedMessage';
import { InternalBadge } from './internal-badge/InternalBadgeComponent';

export interface ICategoryCardProps {
  service: IService;
  raising?: boolean;
}

export const ServiceCard: FC<ICategoryCardProps> = ({ service, raising }) => {
  const { formatMessage } = useIntl();
  const context = useGlobalContext();
  return (
    <div
      className={`card ${raising ? 'card-bordered card-raising' : ''}`}
    >
      <div className="position-relative">
        <div className="card-body service-card-header">
          <h3>{service.name}</h3>
          {service.subCategory && (
            <WrappedFormattedMessage
              id={`sub-category.${service.subCategory}`}
              tag="p"
              className="card-subtitle text-muted font-weight-medium fs-14"
            />
          )}
          {service.isInternal && <InternalBadge />}
        </div>
        <Link
          className="stretched-link"
          to={`/${context.language}/service/${servicePath(service)}`}
        >
          <img
            src={
              service.images[0] &&
                service.images[0].startsWith('https://')
                ? service.images[0]
                : `/images/services/${service.images[0]}`
            }
            alt={`Service ${service.name}`}
            style={{ maxHeight: 230 }}
            className="img-fluid card-img mb-3 pl-4"
          />
        </Link>
      </div>
      <div className="card-footer">
        <div className="d-inline-block pt-1">
          <a
            href={shareServiceLink(service, context.language)}
            onClick={() => trackEvent('share', service.serviceKey)}
            className="mr-1"
          >
            <i className="icon">share</i>
          </a>
          {context.internalUser && !context.viewAsClient && (
            <a
              href={service.links.jive}
              onClick={() => trackEvent('jive', service.serviceKey)}
            >
              <i className="icon ml-1 mr-1">chat</i>
            </a>
          )}
        </div>
        <Link
          to={`/${context.language}/service/${servicePath(service)}`}
          className="btn btn-text-icon sgbs-btn-default float-right"
        >
          {formatMessage({ id: 'home.service.details' })}
          <i className="icon">arrow_forward</i>
        </Link>
      </div>
    </div>
  );
};
