import React, { FC, PropsWithChildren } from 'react';
import { MiniFooter } from '../Header/MiniFooter';
import { AccountCenterPublic } from '../Header/AccountCenterPublic';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import { HelpCenter } from '../HelpCenter/HelpCenter';
import logoMini from '../../assets/images/brand-mini.png';

export const SubscriptionLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="d-flex min-vh-100 flex-column">
      <header>
        <nav
          className={`navbar-light d-flex justify-content-between align-content-center
                    navbar navbar-expand-lg navbar-light page-header`}
        >
          <NavbarBrand>
            <img src={logoMini} alt="SG Markets" />
          </NavbarBrand>
          <AccountCenterPublic />
        </nav>
      </header>
      <div className="container-fluid info-content d-flex justify-content-center align-items-center pb-5 flex-grow-1">
        {children}
      </div>
      <div className="mt-5">
        <MiniFooter containerClass="container-fluid" />
      </div>
      <HelpCenter />
    </div>
  );
};
