import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { SHAREINBOX_SERVICE_KEY, SHAREINBOX_SERVICE_NAME } from '../models';

const apiServiceBoardBase = process.env.REACT_APP_INFO_PROXY_BASE;

interface IServices extends Record<string, string> { }

const loadSgmServices: (callback: (services: IServices) => void) => void = (callback) => {
  const config = {
    url: `${apiServiceBoardBase}/v1/info/sgm-services`,
    method: 'GET',
    timeout: 5000,
    headers: {},
    withCredentials: true,
  };
  axios(config).then(({ data }) => {
    const serviceRecord: IServices = (data as any[]).reduce((dict, current) => {
      dict[current.serviceKey] = current.name;
      return dict;
    }, {});

    // Somes services are not available in request-access service list (they are "notApplicable" in SGM Info), 
    // But they need a special case in request-access and demo form page
    // To remove if special case are not more used (directly check for serviceKey references), or if services are fully integrated in SGM Info
    if (!Object.keys(serviceRecord).find(x => x.toLowerCase() === SHAREINBOX_SERVICE_KEY.toLowerCase())) {
      serviceRecord[SHAREINBOX_SERVICE_KEY] = SHAREINBOX_SERVICE_NAME;
    }
    callback(serviceRecord);
  });
};

const sortByKey = (keys: IServices): string[] =>
  Object.keys(keys).sort((a, b) => keys[a].localeCompare(keys[b]));

export const ServiceSelectOptions: FC = () => {
  const { formatMessage } = useIntl();
  const [services, setServices] = useState<IServices>({});

  useEffect(() => {
    loadSgmServices(s => setServices(s));
  }, []);

  return (
    <>
      <option value="">
        {formatMessage({
          id: 'service-option-selector',
          defaultMessage: 'Search or select service',
        })}
      </option>
      {sortByKey(services).map(v => (
        <option key={v} value={v.toLocaleUpperCase()}>
          {services[v]}
        </option>
      ))}
    </>
  );
};
