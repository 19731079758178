import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export interface IResponsiveTextProps {
  text: string;
  translate?: boolean;
  mobileClasses?: string;
  screenClasses?: string;
  className?: string;
  tagName: string;
  display?: string;
}

export const ResponsiveText: FC<IResponsiveTextProps> = (props: IResponsiveTextProps) => {
  const { className, mobileClasses, screenClasses } = props;
  const CustomTag = `${props.tagName}` as keyof JSX.IntrinsicElements;
  const display = props.display || 'd-sm-block';
  if (props.translate) {
    return (
      <FormattedMessage id={props.text}>
        {
          txt => (
            <>
              <CustomTag className={`d-none ${display} ${className || ''} ${screenClasses || ''}`}>{txt}</CustomTag>
              <CustomTag className={`d-sm-none ${className || ''} ${mobileClasses || ''}`}>{txt}</CustomTag>
            </>
          )
        }
      </FormattedMessage>
    );
  }
  return <>
    <CustomTag className={`d-none ${display} ${className || ''} ${screenClasses || ''}`}>{props.text}</CustomTag>
    <CustomTag className={`d-sm-none ${className || ''} ${mobileClasses || ''}`}>{props.text}</CustomTag>
  </>;
};
