import React, { FC } from 'react';
import { IService } from '../../data/data.types';
import { ImagesCarousel } from '../Common/ImagesCarousel';
import { ResponsiveComponent } from '../Common/ResponsiveComponent';
import { noop } from '../../services/utils.service';
import { initializeQumuPlayer } from '../../services/qumu.service';

export interface IServiceScreenshotsProps {
  service: IService;
}

export const ServiceScreenshots: FC<IServiceScreenshotsProps> = ({ service }) => {
  const screens: any[] = [];
  if (service.links.video) {
    screens.push({
      key: `${service.serviceKey}-video`,
      video: service.links.video,
    });
    initializeQumuPlayer(
      service.links.video,
      '#qumu-player-next-mobile',
      noop,
      noop
    );
  }
  for (let index = 0; index < 3; index++) {
    if (!service.images[index]) {
      break;
    }
    screens.push({
      key: `${service.serviceKey}-screen-${index}`,
      alt: `${service.name} screen ${index + 1}`,
      source: service.images[index].startsWith('https://')
        ? service.images[index]
        : `/images/services/${service.images[index]}`,
    });
  }

  const screenView = (
    <div className="container">
      <ImagesCarousel images={screens} />
    </div>
  );
  const mobileView = (
    <div className="my-3 col-12 d-inline-flex">
      {screens
        .filter(s => !s.hasOwnProperty('video'))
        .map((image, index) => {
          return (
            <div
              key={image.key}
              className={`border border-secondary ${index > 0 ? 'ml-3' : ''}`}
            >
              <img
                src={image.source}
                alt={image.alt}
                style={{ width: 'auto', maxWidth: '80vw', height: 'auto' }}
              />
            </div>
          );
        })}
      {service.links.video && (
        <div
          style={{ width: '60vw', height: 'auto' }}
          className="border border-secondary ml-3 my-auto"
        >
          <div id="qumu-player-next-mobile" />
        </div>
      )}
    </div>
  );

  return (
    <div className="container">
      <ResponsiveComponent
        componentForScreen={screenView}
        componentForMobile={mobileView}
        mobileClasses="mx-0"
      />
    </div>
  );
};
