export function trackEvent(event: string, value: string): void {
  if (
    (document.querySelector('sgwt-web-analytics') as any).trackEvent ===
    undefined
  ) {
    return;
  }

  (document.querySelector('sgwt-web-analytics') as any).trackEvent(
    event,
    value
  );
}

export function trackSearch(keyword: string, category: string): void {
  if (
    (document.querySelector('sgwt-web-analytics') as any).trackSearch ===
    undefined
  ) {
    return;
  }

  (document.querySelector('sgwt-web-analytics') as any).trackSearch(
    keyword,
    category
  );
}
