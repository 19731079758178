import axios, { AxiosRequestConfig } from 'axios';
import {
  IService,
  IAccessibleServiceList,
  ISubscriptionInfoToDisplayModel,
} from '../data/data.types';
import { logger } from './utils.service';
import { SGWTConnectCore } from '@sgwt/connect-core';

const SERVICE_BOARD_API_BASE = process.env.REACT_APP_SERVICE_BOARD_API_BASE;
const INFO_PROXY_BASE = process.env.REACT_APP_INFO_PROXY_BASE;
const SG_CONNECT_API_BASE = process.env.REACT_APP_SG_CONNECT_ENDPOINT;
const CLIENT_SUBSCRIPTION_LIST_BASE = process.env.REACT_APP_CLIENT_SUBSCRIPTION_LIST_BASE;
const SG_NETWORK = 'intranet';

export interface ISgwtConnect extends Element {
  sgwtConnect: SGWTConnectCore;
}

/**
 * Find the SGWT Connect.
 */
export function getSgwtConnect(): SGWTConnectCore {
  return (document.querySelector('sgwt-connect') as ISgwtConnect).sgwtConnect;
}

export function signIn(): void {
  const sgConnect = getSgwtConnect();
  sgConnect.requestAuthorization();
}

const tryAddAuthorizationHeader = (
  config: AxiosRequestConfig,
  allowAnonymous: boolean = true
): AxiosRequestConfig => {
  const sgwtConnect: any = getSgwtConnect();
  if (sgwtConnect.isAuthorized()) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: sgwtConnect.getAuthorizationHeader(),
      },
    };
  }

  if (!allowAnonymous) {
    sgwtConnect.requestAuthorization();
  }

  return config;
};

/**
 * Retrieve the services from the API.
 */
export function retrieveServices(
  cb: (err: any, services: IService[] | null) => void
): void {
  const config = {
    url: `${INFO_PROXY_BASE}/v1/info/sgm-services`,
    method: 'GET',
    timeout: 10000,
    headers: {},
  };
  axios(tryAddAuthorizationHeader(config))
    .then(({ data }) => {
      logger.debug(`${data.length} services retrieved from ${data.source}.`);
      cb(null, data);
    })
    .catch(error => {
      logger.error('Could not retrieve Services data', error);
      cb(error, null);
    });
}

/**
 * For a connected user, we try to define the list of accessible services.
 */
export function retrieveAccessibleServices(
  cb: (err: any, data: IAccessibleServiceList | null) => void
): void {
  const config = {
    url: `${SERVICE_BOARD_API_BASE}/v2/services?accessType=hasAccessWithoutHiddenConsideration`,
    method: 'GET',
    timeout: 10000,
    headers: {},
  };
  axios(tryAddAuthorizationHeader(config, false))
    .then(({ data }) => {
      cb(null, data);
    })
    .catch(error => {
      logger.error('Not able to retrieve accessible services', error);
      cb(error, null);
    });
}

/**
 * Check if the user is on internal network
 */
export async function internalNetwork(): Promise<boolean> {
  const config = {
    url: `${SG_CONNECT_API_BASE}/network/api/v1/origin`,
    method: 'GET',
    timeout: 5000,
  };
  try {
    const response = await axios(config);
    if (
      response.data &&
      response.data['origin-network'] &&
      response.data['origin-network'].toLowerCase() === SG_NETWORK
    ) {
      return true;
    }
  } catch (err) {
    logger.debug(err);
  }
  return false;
}

/**
 * Retrieve subscription infos.
 */
export function retrieveSubscriptionInfos(
  code: string | null | undefined,
  cb: (
    err: any,
    subscriptionInfoToDisplay: ISubscriptionInfoToDisplayModel | null
  ) => void
): void {
  const config = {
    url: `${CLIENT_SUBSCRIPTION_LIST_BASE}/api/v1/client-subscription-lists/do-get-subscription-infos-to-display`,
    method: 'POST',
    timeout: 10000,
    headers: {},
    data: {
      code,
    },
  };
  axios(tryAddAuthorizationHeader(config))
    .then(({ data }) => {
      cb(null, data);
    })
    .catch(error => {
      logger.error('Could not retrieve subscription infos', error);
      cb(error, null);
    });
}

/**
 * Unsubscribe to client subscription list
 */
export function unsubscribeToList(
  code: string | null | undefined,
  cb: (err: any) => void
): void {
  const config = {
    url: `${CLIENT_SUBSCRIPTION_LIST_BASE}/api/v1/client-subscription-lists/do-unsubscribe`,
    method: 'POST',
    timeout: 10000,
    headers: {},
    data: {
      code,
    },
  };
  axios(tryAddAuthorizationHeader(config))
    .then(() => {
      cb(null);
    })
    .catch(error => {
      logger.error('Could not unsubscribe to client subscription list', error);
      cb(error);
    });
}
