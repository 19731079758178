import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/global.context';
import { IService } from '../../data/data.types';
import { getRelatedServices, servicePath } from '../../services/utils.service';
import { ServiceMiniCard } from './ServiceMiniCard';
import { ResponsiveComponent } from '../Common/ResponsiveComponent';
import { ResponsiveText } from '../Common/ResponsiveText';
import { SERVICE_PAGE_MAX_RELATED_SERVICES } from '../../common/constants';

export interface IRelatedServicesListProps {
  service: IService;
}

export const RelatedServicesList: FC<IRelatedServicesListProps> = ({ service }) => {
  const context = useGlobalContext();
  const relatedServices = getRelatedServices(
    service,
    context.services
  ).slice(0, SERVICE_PAGE_MAX_RELATED_SERVICES);
  if (relatedServices.length === 0) {
    return null;
  }

  const screenView = relatedServices.map((related: IService) => (
    <div className="col-12 col-sm-6" key={related.serviceKey}>
      <ServiceMiniCard service={related} />
    </div>
  ));
  const mobileView = relatedServices.map((related: IService) => (
    <div
      className="ml-3"
      style={{ width: '25vw' }}
      key={related.serviceKey}
    >
      <Link
        to={`/${context.language}/service/${servicePath(related)}`}
        aria-label={'link to ' + related.name}
      >
        <div className="d-inline-block border shadow-sm display-3 initials-box mini-card bg-white">
          <span className="text-uppercase font-weight-normal text-light">
            {related.initials}
          </span>
        </div>
      </Link>
      <div className="fs-14 font-weight-medium" style={{ lineHeight: 1 }}>
        {related.name}
      </div>
    </div>
  ));

  return (
    <div className="mt-3 mt-md-5 pb-3 bg-lvl2">
      <div className="container pt-4">
        <ResponsiveText
          tagName="h4"
          text="service.relatedServices"
          translate
          className="font-weight-bold mt-3 mb-3"
          screenClasses="display-4"
          mobileClasses="display-5"
        />
        <ResponsiveComponent
          componentForScreen={screenView}
          componentForMobile={mobileView}
        />
      </div>
    </div>
  );
};
