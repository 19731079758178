import React from 'react';
import { useIntlExtended } from 'common/reactIntlExtended';

export const ShareinboxHelpFormPart = () => {
    const { t } = useIntlExtended();

    return <span>
        {t('request-access.form.service.shareinbox.help.part.1')}
        <a
            href="https://sharinbox.sgmarkets.com/nousContacter"
            rel="noopener noreferrer"
            className="text-danger"
        >
            {t('request-access.form.service.shareinbox.help.part.2')}
            <i className="icon ml-1">open_in_new</i>
        </a>
        {t('request-access.form.service.shareinbox.help.part.3')}
    </span>;
};