import { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';

// When we change the page, we would like to see the top of the page...
// cf. https://reacttraining.com/react-router/web/guides/scroll-restoration
export const ScrollToTop: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};
