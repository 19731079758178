import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntlExtended } from 'common/reactIntlExtended';
import { InputForm, RadioForm } from '../inputs';
import { IRequestAccessContent } from '../models';

type PersonalFormContext = Pick<IRequestAccessContent, 'firstName' | 'lastName' | 'jobTitle' | 'email' | 'professionalPhoneNumber' | 'isMifid2Eligible'>;

interface IPersonalFormPartProps {
    titleOrder: number;
    showProfessionalPhoneNumber?: boolean;
    showMifid?: boolean;
}

const mifid2Options = [
    {
        labelId: 'request-access.form.mifid.yes',
        value: 'yes',
    },
    {
        labelId: 'request-access.form.mifid.no',
        value: 'no',
    },
];

export const PersonalFormPart: FC<IPersonalFormPartProps> = ({ titleOrder, showProfessionalPhoneNumber, showMifid }) => {
    const { t } = useIntlExtended();
    const { register, watch, formState: { errors, isSubmitting } } = useFormContext<PersonalFormContext>();

    return <>
        <p className="text-xlarge font-weight-medium mb-4 spacing-mt-2">
            {t('request-access.form.details', { count: titleOrder })}
        </p>

        <div className="form-group mb-0">
            <label className="mb-1">
                {t('request-access.form.details.name')}
            </label>
            <div className="d-flex d-row gap-3">
                <InputForm
                    placeholderId="request-access.form.details.name.firstname.placeholder"
                    register={register}
                    errors={errors}
                    name="firstName"
                    disabled={isSubmitting}
                />
                <InputForm
                    placeholderId="request-access.form.details.name.lastname.placeholder"
                    register={register}
                    errors={errors}
                    name="lastName"
                    disabled={isSubmitting}
                />
            </div>
        </div>

        <InputForm
            labelId="request-access.form.details.jobtitle"
            placeholderId="request-access.form.details.jobtitle.placeholder"
            register={register}
            errors={errors}
            name="jobTitle"
            disabled={isSubmitting}
        />

        <InputForm
            type="email"
            labelId="request-access.form.details.email"
            placeholderId="request-access.form.details.email.placeholder"
            register={register}
            errors={errors}
            name="email"
            disabled={isSubmitting}
        />
        {showProfessionalPhoneNumber &&
            <InputForm
                labelId="request-access.form.company.professionalPhoneNumber"
                placeholderId="request-access.form.company.professionalPhoneNumber.placeholder"
                register={register}
                errors={errors}
                name="professionalPhoneNumber"
                disabled={isSubmitting}
            />}
        {showMifid && <>
            <p className="text-xlarge font-weight-medium mb-4 spacing-mt-2">
                {t('request-access.form.mifid', { count: titleOrder + 1 })}
            </p>
            <RadioForm
                register={register}
                errors={errors}
                watch={watch}
                name="isMifid2Eligible"
                options={mifid2Options}
                disabled={isSubmitting}
            />
        </>}
    </>;
};
