import React, { FC, useEffect, useState } from 'react';
import Collapse from 'reactstrap/lib/Collapse';
import NavbarToggler from 'reactstrap/lib/NavbarToggler';
import Nav from 'reactstrap/lib/Nav';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavItem from 'reactstrap/lib/NavItem';
import { FormattedMessage } from 'react-intl';
import { LANGUAGE, useGlobalContext } from '../../context/global.context';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Search } from '../Search/Search';
import { AccountCenter } from './AccountCenter';
import { sortCategories, toggleMobileMenu } from '../../services/utils.service';
import { LanguageSwitcher } from './LanguageSwitcher';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';
import { signIn } from '../../services/api.service';
import logoEN from '../../assets/images/sg-brand-en.svg';
import logoFR from '../../assets/images/sg-brand-fr.svg';
import logoMini from '../../assets/images/brand-mini.png';

import './Header.scss';

export interface IHeaderProps {
  highlightedCategory?: string;
}

export interface IHeaderState {
  collapsed: boolean;
  hideMenu: boolean;
  lastScroll: number;
  search: boolean;
}

export const Header: FC<IHeaderProps> = ({ highlightedCategory }) => {
  const locationPathname = useLocation().pathname;
  const [collapsed, setCollapsed] = useState(true);
  const [hideMenu, setHideMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const context = useGlobalContext();

  const scrolling: EventListener = () => {
    const top = document.documentElement!.scrollTop;
    if (toggleMobileMenu(lastScroll, hideMenu)) {
      setHideMenu(!hideMenu);
      setLastScroll(top);
    } else {
      setLastScroll(top);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrolling);
    return () => { window.removeEventListener('scroll', scrolling); };
  }, [scrolling]);

  const handleToggle = (): void => {
    setCollapsed(!collapsed);
  };

  const handleSearch = (show: boolean, event?: React.MouseEvent): void => {
    setSearch(show);

    if (event) {
      event.stopPropagation();
    }
  };

  return (
    <>
      {search ? (
        <nav style={{ zIndex: 1024 }} className="page-header p-2">
          <Search
            header={true}
            onClose={() => handleSearch(false)}
            showIt={true}
          />
        </nav>
      ) : (
        <nav
          className={`navbar-light d-flex justify-content-between align-content-center
                        navbar navbar-expand-lg navbar-light page-header
                        ${hideMenu ? 'hide-mobile-menu' : ''}
                        ${collapsed ? '' : 'mobile-navbar-opened'
            }`}
        >
          <NavbarBrand href={`/${context.language}`}>
            <img
              src={context.language === LANGUAGE.FR ? logoFR : logoEN}
              alt="SG Markets"
              className="d-none d-lg-block the-future-is-you"
            />
            <img
              src={logoMini}
              alt="SG Markets"
              className="d-block d-lg-none"
            />
          </NavbarBrand>

          <NavbarToggler
            onClick={handleToggle}
            className="mr-auto d-md-none"
          >
            <WrappedFormattedMessage
              id="global.menu"
              tag="span"
              className="fs-14"
            />
            <i className="icon icon-md text-secondary">arrow_drop_down</i>
          </NavbarToggler>

          {/* Search on large-screens */}
          <div className="d-none d-sm-block search-in-header">
            <Search header={true} />
          </div>
          {/* Search on mobile > a button that opens a modal for search... */}
          <button
            className="btn btn-flat-black btn-lg px-2 d-sm-none"
            onClick={(evt) => handleSearch(true, evt)}
          >
            <i className="icon icon-md">search</i>
          </button>

          <AccountCenter />

          <Collapse isOpen={!collapsed} navbar>
            <Nav navbar>
              {Object.keys(context.categories)
                .sort(sortCategories())
                .map(category => {
                  const active =
                    locationPathname.indexOf(`/${category}`) !== -1 ||
                    highlightedCategory === category;
                  return (
                    <NavItem
                      className="d-md-none"
                      key={`header-menu-${category}`}
                    >
                      <Link
                        to={`/${context.language}/category/${category}`}
                        className={`nav-link ${active ? 'active border-socgen' : ''}`}
                      >
                        <FormattedMessage id={`category.${category}`} />
                      </Link>
                    </NavItem>
                  );
                })}
              {!context.user && (
                <>
                  <LanguageSwitcher />

                  <li className="nav-item">
                    <button
                      className="btn btn-xl nav-link h-100"
                      onClick={() => signIn()}
                    >
                      <FormattedMessage id="global.signIn" />
                    </button>
                  </li>
                </>
              )}
            </Nav>
          </Collapse>
        </nav>
      )}

      {/* Category navigation sticky bar */}
      <nav
        className={`sticky-top navbar navbar-expand-lg navbar-light bg-white p-0
        border-bottom justify-content-center d-none d-md-flex`}
      >
        <div className="sg-navbar-container">
          <ul className="nav nav-pills">
            <li className="nav-item nav-hover">
              <Link
                to={`/${context.language}/`}
                className={`nav-link ${highlightedCategory === 'sg-markets'
                  ? 'active border-socgen'
                  : ''
                  }`}
              >
                SG Markets
              </Link>
            </li>
            {Object.keys(context.categories)
              .sort(sortCategories())
              .map(category => {
                const active =
                  locationPathname.indexOf(`/category/${category}`) !== -1 ||
                  highlightedCategory === category;
                return (
                  <li
                    className="nav-item nav-hover"
                    key={`header-menu-${category}`}
                  >
                    <Link
                      to={`/${context.language}/category/${category}`}
                      className={`nav-link ${active ? 'active border-socgen' : ''
                        }`}
                    >
                      <FormattedMessage id={`category.${category}`} />
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </nav>
    </>
  );
};
