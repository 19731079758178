import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface IHeadSubCategoryPanelProps {
  activeTheme: string | null;
  category: string;
  themes: string[];
  onActiveThemeChange: (theme: string) => void;
}
export const HeadSubCategoryPanel = (props: IHeadSubCategoryPanelProps) => (
  <>
    {['all', ...props.themes].map((theme, index) => (
      <li
        key={`sub-category-menu-${props.category}-${theme}`}
        className="nav-item text-xlarge"
      >
        <FormattedMessage id={`theme.${theme}`}>
          {txt =>
            (!props.activeTheme && index === 0) ||
            props.activeTheme === theme ? (
              <span
                className="text-xlarge nav-link active ml-0"
                style={{ whiteSpace: 'nowrap' }}
              >
                {txt}
              </span>
            ) : (
              <button
                type="button"
                className="btn-link btn text-xlarge nav-link font-weight-bold pointer"
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                  props.onActiveThemeChange(theme);
                }}
              >
                {txt}
              </button>
            )
          }
        </FormattedMessage>
      </li>
    ))}
  </>
);
