import * as React from 'react';
import { IService } from '../../../data/data.types';
import { ServicesColumn } from '../../Common/ServicesColumn';

interface IOnlyListPanelProps {
  services: IService[];
}

export const OnlyListPanel = (props: IOnlyListPanelProps) => {
  const groups: IService[][] = [
    props.services.slice(0, 11),
    props.services.slice(11, 22),
    props.services.slice(22, 33),
  ];

  return (
    <>
      {/* Large screens */}
      <div className="row d-none d-md-flex ml-3">
        {groups.map((services: IService[], index: number) => (
          <div key={index} className="col-sm-4">
            <ServicesColumn services={services} />
          </div>
        ))}
      </div>

      {/* Mobile view */}
      <div className="row responsive-slider flex-nowrap d-md-none">
        {groups.map((services: IService[], index: number) => (
          <div key={index} className="responsive-slider-card ml-3">
            <ServicesColumn services={services} />
          </div>
        ))}
      </div>
    </>
  );
};
