import * as React from 'react';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';
import { displayCookieConsents } from './cookieConsent';

export const NoVideo = () => (
  <div className="mr-3 w-100">
    <div className="jumbotron h-100 text-center" style={{ minWidth: '400px' }}>
      <WrappedFormattedMessage
        id="no-video.title"
        tag="h1"
        className="display-4"
      />
      <WrappedFormattedMessage id="no-video.line1" tag="p" className="lead" />
      <WrappedFormattedMessage id="no-video.line2" tag="p" className="lead" />
      <p className="lead">
        <button
          className="btn btn-primary btn-lg"
          onClick={() => {
            displayCookieConsents();
          }}
        >
          <WrappedFormattedMessage id="no-video.button" tag="span" />
        </button>
      </p>
    </div>
  </div>
);
