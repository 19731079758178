import React, { FC } from 'react';
import { IAward } from '../../data/data.types';

import './Award.scss';

export interface IAwardCardProps {
  award: IAward;
}

export const AwardCard: FC<IAwardCardProps> = ({ award }) => {
  return (
    <div className="award">
      <div className="card card-bordered mb-2">
        <div
          className={`mx-auto award-logo ${award.id}`}
          style={{
            background: `url(/images/awards/${award.image}) no-repeat 50%`,
            backgroundSize: 'contain',
          }}
          title={award.title}
        />
      </div>
      <div className="text-muted">
        {award.title ? <span className="h5">{award.title}</span> : null}
        <ul className="list-group d-none d-sm-block">
          {award.list.map(item => (
            <li className="list-group-item p-0" key={item}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
