import React, { FC } from 'react';
import { Error } from './Error';
import imageUrl from '../../assets/images/404.svg';

import './Error.scss';

export const NotFound: FC = () => {
  return (
    <Error
      code={404}
      title={'error.notfound.title'}
      message={'error.notfound.message'}
      imageUrl={imageUrl}
    />
  );
};

export default NotFound;
