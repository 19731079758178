import { SGWTConnectCore } from '@sgwt/connect-core';

export const DATE_FORMAT = 'DD MMM YYYY';
// export const DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm';

export interface ISgwtConnectElement extends HTMLElement {
  sgwtConnect: SGWTConnectCore;
  requestAuthorization: (
    cbSuccess: () => void,
    cbError: (err: Error) => void
  ) => void;
}

export const SEARCH_DEFAULT_WIDTH = 540;
export const SEARCH_MAX_SERVICES_DISPLAYED = 3;

export const HOMEPAGE_VIDEO_ID = 'EnOp3uunIk9';

export const CATEGORY_PAGE_MAX_THEMES_DISPLAYED = 5;
export const CATEGORY_PAGE_SERVICES_CARDS_PER_THEME = 4;

export const THEME_PAGE_MAX_HIGHLIGHTED_THEMES = 2;
export const THEME_PAGE_MAX_DICOVER_MORE_THEMES = 4;

export const SERVICE_PAGE_MAX_RELATED_SERVICES = 6;

/**
 * For authenticated users, we check their address domain to see if they are considered as internal.
 */
export const VALID_MAIL_DOMAINS = [
  'sgcib.com',
  'sgss.socgen.com',
  'socgen.com',
  'aptp.accenture.com',
  'cdn.fr',
  'descartes-trading.com',
  'hermes.si.socgen',
  'kb.cz',
  'kleinwortbenson.com',
  'lyxor.com',
  'newedge.com',
  'orbeo.com',
  'rosbank.ru',
  's2e-net.com',
  'sghambros.com',
  'sgpriv.be',
  'sgprivasia.com',
  'sgprivjapan.com',
  'sgss.com',
  'sgss.socgen.co.za',
  'sgss.socgen.de',
  'sgss.socgen.it',
  'sgsssocgen.com',
  'smbctb.co.jp',
  'socgen.com.cn',
  'socgen.it',
];

// Default order of categories
export const DEFAULT_CATEGORY_ORDER = [
  'markets',
  'financing',
  'global-transaction-banking',
  'securities',
  'equipmentfinance',
  'asset-management',
  'transversal',
];

// Order of categories for the Large Corpo & SME theme pages
export const CLIENT_TYPE_PAGE_CATEGORY_ORDER = [
  'global-transaction-banking',
  'markets',
  'financing',
  'securities',
  'equipmentfinance',
  'asset-management',
  'transversal',
];

// Threshold (in pixels) when we start to hide the menu in mobile view.
export const MOBILE_SCROLL_THRESHOLD = 150;

// Add constants for data that comes from the API.
export const CATEGORY_PRE_TRADE = 'pre-trade';
export const CATEGORY_POST_TRADE = 'post-trade';
export const CLIENT_TYPE_SMALL_ENTERPRISE = 'CORP - Small (7.5 M€ - 75 M€)';
export const CLIENT_TYPE_MEDIUM_ENTERPRISE = 'CORP - Medium (75 M€ - 1 Md€)';
export const CLIENT_TYPE_LARGE_CORPORATE = 'CORP - Large (> 1 Md€)';
export const THEME_LARGE_CORPORATES = 'large-corporates';
export const THEME_SMALL_MEDIUM_ENTERPRISES = 'small-medium-enterprises';
export const THEME_RISK_FINANCE = 'risk-finance';
