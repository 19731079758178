/**
 * This file instantiate the GlobalContext.
 * TODO: Split global context in order to get specific logic instantiated at the right moment
 *
 * Good to know: This file trigger an autologin for users using sg internal network.
 */
import React, { FC, useEffect, useState } from 'react';
import allThemes from '../../data/all-themes.json';

import {
  defaultContext,
  GlobalContext,
  IGlobalContext,
  LANGUAGE,
} from '../../context/global.context';
import {
  IAccessibleServiceList,
  IService,
  IServiceTheme,
} from '../../data/data.types';
import {
  getSgwtConnect,
  retrieveAccessibleServices,
  retrieveServices,
  internalNetwork,
} from '../../services/api.service';
import {
  getServicesClientTypes,
  isInternalUser,
  logger,
  splitServicesAmongCategories,
  sortThemes,
} from '../../services/utils.service';
import {
  ERR_INTERACTION_REQUIRED,
  EVENT_AUTH_RENEW_ERROR,
  EVENT_AUTH_RENEW_SUCCESS,
  SGWTConnectCore,
  SGWTConnectError,
} from '@sgwt/connect-core';
import { ISGWTConnectIdTokenClaims } from '@sgwt/connect-core/dist/src/SGWTConnectIdTokenClaims';
import { useLocation, useNavigate } from 'react-router';

interface IServicesProviderProps {
  children: (context: IGlobalContext) => JSX.Element;
}

interface IStateContext extends Omit<IGlobalContext,
  'getTheme' | 'getCategoryServices' | 'getSubCategoryServices' | 'getThemeServices' | 'sortThemeNames'
  | 'changeLanguage' | 'setUser' | 'navigateAsClient' | 'endNavigateAsClient'> { }

export const ServicesProvider: FC<IServicesProviderProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState<IStateContext>({ ...defaultContext });

  useEffect(() => {
    (async () => {
      if (location && location.pathname.match(/(en|fr)\/(request-access|request-demo|subscription)/)) {
        return;
      }

      callServicesAPI();
      const sgConnect: SGWTConnectCore = getSgwtConnect();

      if (!sgConnect.isAuthorized()) {
        const internal = await internalNetwork();
        if (internal) {
          sgConnect.requestAuthorization();
          return;
        }
      }

      if (sgConnect.getAuthorizationError()) {
        // ERROR While the requesting authorization...
        document.body.innerHTML = `
<div class="alert alert-danger" role="alert">
  Authorization error: ${sgConnect.getAuthorizationError()}.
</div>
`;
      } else if (sgConnect.isAuthorized()) {
        userConnected(sgConnect);
      } else {
        tryRenewToken(sgConnect);
      }
    })();
  }, []);

  /**
   * Call the API to retrieve the list of services, and update the Global Context accordingly.
   */
  const callServicesAPI = (): void => {
    retrieveServices((error: any, services: IService[] | null) => {
      if (services) {
        const sortedServices = services.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        const categories = splitServicesAmongCategories(sortedServices);
        const clientTypes = getServicesClientTypes(sortedServices);
        setState((oldState) => ({
          ...oldState,
          dataLoaded: true,
          retrievedServices: sortedServices,
          services: sortedServices,
          categories,
          clientTypes,
        }));
      } else {
        setState((oldState) => ({
          ...oldState,
          dataError: error,
        }));
      }
    });
  };

  /**
   * User is connected. We try to retrieve the list of services s·he has access to, and update the Global Context.
   */
  const userConnected = (sgwtConnect: SGWTConnectCore): void => {
    // User connected...
    const user: ISGWTConnectIdTokenClaims | null = sgwtConnect.getIdTokenClaims();
    if (user !== null) {
      setUser(user.sub);
      // Get the list of accessible services
      retrieveAccessibleServices(
        (error: any, data: IAccessibleServiceList | null) => {
          if (data && data.Services && !error) {
            const accessibleServices = data.Services.map(s =>
              s.ServiceKey.toLowerCase()
            );
            logger.debug(
              'Accessible services for the user',
              accessibleServices
            );
            setState((oldState) => ({
              ...oldState,
              accessibleServices,
            }));
          }
        }
      );
    }
  };

  const tryRenewToken = (sgwtConnect: SGWTConnectCore): void => {
    logger.debug('Trying to log the user silently...');
    // Listen to the renew success. It means that we successfully authenticates the user...
    sgwtConnect.once(EVENT_AUTH_RENEW_SUCCESS, () => {
      logger.debug('Renew succeed');
      callServicesAPI();
      userConnected(sgwtConnect);
    });

    // Listen to the renew failure. Two cases:
    //   - if the error is
    sgwtConnect.once(EVENT_AUTH_RENEW_ERROR, (error: SGWTConnectError) => {
      logger.debug('Renew failed');
      if (error.code === ERR_INTERACTION_REQUIRED) {
        // INTERACTION REQUIRED - We do nothing.
        logger.debug('Renew failed because INTERACTION is required.');
      } else {
        logger.debug(`Renew failed because the error "${error.code}"`, error);
      }
    });

    sgwtConnect.renewAuthorization();
  };

  const getTheme = (id: string): IServiceTheme | null => {
    for (const theme of state.themes) {
      if (theme.id === id) {
        return theme;
      }
    }
    return null;
  };

  const getCategoryServices = (category: string): IService[] => {
    return state.services.filter((s: IService) => s.category === category);
  };

  const getSubCategoryServices = (subCategory: string): IService[] => {
    return state.services.filter(
      (s: IService) => s.subCategory === subCategory
    );
  };

  const getThemeServices = (theme: string): IService[] => {
    return state.services.filter(
      (s: IService) => s.themes.indexOf(theme) > -1
    );
  };

  const sortThemeNames = (themes: string[]): string[] => {
    const matchingThemes = themes
      .map(theme => getTheme(theme))
      .filter(foundTheme => foundTheme) as IServiceTheme[];
    matchingThemes.sort(sortThemes);
    return matchingThemes.map(theme => theme.id);
  };

  const changeLanguage = (newLanguage: string): void => {
    const current = state.language;
    if (newLanguage && newLanguage.toLowerCase() !== current) {
      // Change URL

      const path = location.pathname.replace(
        new RegExp(`^\/${current}`),
        `/${newLanguage}`
      );

      navigate(path);

      setState((oldState) => ({
        ...oldState,
        language: newLanguage.toLowerCase() === 'fr' ? LANGUAGE.FR : LANGUAGE.EN,
      }));
    }
  };

  const updateUserViewStatus = (user: string | null, viewAsClient: boolean): void => {
    const internalUser = isInternalUser(user);
    if (internalUser && !viewAsClient) {
      const categories = splitServicesAmongCategories(
        state.retrievedServices
      );
      setState((oldState) => ({
        ...oldState,
        themes: allThemes as IServiceTheme[],
        user,
        viewAsClient,
        internalUser,
        services: state.retrievedServices,
        categories,
      }));
    } else {
      const services = state.retrievedServices.filter(
        (s: IService) => !s.isInternal
      );
      const categories = splitServicesAmongCategories(services);
      setState((oldState) => ({
        ...oldState,
        user,
        viewAsClient,
        internalUser,
        services,
        categories,
      }));
    }
  };

  const setUser = (user?: string): void => {
    if (user) {
      updateUserViewStatus(user, false);
      // TODO https://sgithub.fr.world.socgen/SGWT-Projects/new-info/issues/20
      // http.get(availableServices!)
    } else {
      updateUserViewStatus(null, false);
    }
  };

  const navigateAsClient = (): void => {
    if (state.user && state.internalUser && !state.viewAsClient) {
      updateUserViewStatus(state.user, true);
    }
  };

  const endNavigateAsClient = (): void => {
    if (state.user && state.internalUser && state.viewAsClient) {
      updateUserViewStatus(state.user, false);
    }
  };

  const globalContext: IGlobalContext = {
    ...state,
    getCategoryServices: getCategoryServices,
    getSubCategoryServices: getSubCategoryServices,
    getThemeServices: getThemeServices,
    getTheme: getTheme,
    sortThemeNames: sortThemeNames,
    changeLanguage: changeLanguage,
    setUser: setUser,
    navigateAsClient: navigateAsClient,
    endNavigateAsClient: endNavigateAsClient,
  };

  return (
    <GlobalContext.Provider value={globalContext}>
      {children(globalContext)}
    </GlobalContext.Provider>
  );
};
