import React, { FC } from 'react';
import { IService } from '../../data/data.types';
import { ServiceCard } from '../Common/ServiceCard';

export interface IServiceCardsListProps {
  services: IService[];
}

export const ServiceCardsList: FC<IServiceCardsListProps> = ({ services }) => {
  return (
    <>
      {/* Large screens */}
      <div className="row d-none d-sm-flex">
        {
          services.map(service => {
            return (
              <div key={`card-list-${service.serviceKey}`} className="col-12 col-sm-3 mt-4 mb-5">
                <ServiceCard service={service} raising />
              </div>
            );
          })
        }
      </div>

      {/* Mobile view */}
      <div className="row d-sm-none">
        <div className="mobile-horizontal-scroll pl-3 pb-2">
          <div className="d-inline-flex">
            {
              services.map(service => {
                return (
                  <div key={`card-list-mobile-${service.serviceKey}`} style={{ width: '66vw' }} className="mr-3">
                    <ServiceCard service={service} raising />
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </>
  );
};
