import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { SubscriptionLayout } from './SubscriptionLayout';
import { FormattedMessage } from 'react-intl';

export const UnsubscribeValidatedComponent: FC = () => {
  const navigate = useNavigate();

  return (
    <SubscriptionLayout>
      <div className="d-flex flex-column">
        <div>
          <i className="icon icon-xl icon-light icon-grey">mail</i>
        </div>
        <h3 className="display-3">
          <FormattedMessage id="subscription.unsubscribe.validated.title" />
        </h3>
        <h3 className="mt-3 mb-0 text-secondary">
          <FormattedMessage id="subscription.unsubscribe.validated.description.row1" />
        </h3>
        <h3 className="text-secondary">
          <FormattedMessage id="subscription.unsubscribe.validated.description.row2" />
        </h3>
        <div className="font-weight-medium text-secondary mt-3">
          <FormattedMessage id="subscription.unsubscribe.validated.description.row3" />
        </div>
        <div className="d-flex mt-3">
          <button
            className="btn btn-lg btn-discreet-primary"
            onClick={() => navigate('/')}
          >
            <FormattedMessage id="subscription.unsubscribe.btn.go.to.sgmarkets" />
          </button>
        </div>
      </div>
    </SubscriptionLayout>
  );
};
