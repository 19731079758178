import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/global.context';
import { logger } from '../../services/utils.service';
import sgm from '../../assets/images/sgm-logo-watermark.png';

import './Theme.scss';

interface IThemeCardProps {
  themeId: string;
  mode: 'portrait' | 'landscape';
}

export const ThemeCard: FC<IThemeCardProps> = ({ themeId, mode }) => {
  const context = useGlobalContext();
  const theme = context.getTheme(themeId);
  if (!theme) {
    logger.debug(`Theme "${themeId} not found in context...`);
    return null;
  }
  return (
    <>
      <Link
        to={`/${context.language}/theme/${theme.id}`}
        aria-label={'link to ' + theme.id}
        className={`theme-link ${mode} ${mode === 'landscape' && 'mb-3'}`}
      >
        <div
          className={`card shadow-sm card-raising theme-card theme-${themeId} ${mode}`}
        >
          <div className="card-body">
            <img
              src={sgm}
              className="sg-markets-watermark"
              alt="SG Markets"
            />
            <div className="theme-image text-center my-5 text-uppercase d-flex">
              <div
                className={`align-self-center w-100 h-100 ${mode === 'portrait' ? 'display-2' : 'display-3'
                  }`}
              />
            </div>
          </div>
        </div>
      </Link>

      {mode === 'landscape' && (
        <>
          <h3 className="text-primary">
            <FormattedMessage id={`theme.${theme.id}.title`} />
          </h3>
          <p className="text-secondary font-weight-normal theme-description">
            <FormattedMessage id={`theme.${theme.id}.description`} />
          </p>
        </>
      )}
    </>
  );
};
