import React, { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  SEARCH_DEFAULT_WIDTH,
  SEARCH_MAX_SERVICES_DISPLAYED,
} from '../../common/constants';
import { useGlobalContext } from '../../context/global.context';
import { IService, IServiceTheme } from '../../data/data.types';
import {
  isServiceForCorporateThemes,
  servicePath,
  sortCategories,
  sortThemes,
} from '../../services/utils.service';
import { BasicDropdown } from '../Common/BasicDropdown';
import { ServiceAccessButton } from '../Common/ServiceAccessButton';
import { ServiceInitialsBox } from '../Common/ServiceInitialsBox';
import { WrappedFormattedMessage } from '../Common/WrappedFormattedMessage';

// import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { trackSearch } from '../../services/piwik.service';

import './Search.scss';

export interface ISearchProps {
  header: boolean;
  onClose?: () => void;
  showIt?: boolean;
}

const debouncedPiwik = /*AwesomeDebouncePromise*/ (value: string) =>
  trackSearch(value, '') /*,
  500*/;

/**
 * This is the Search feature.
 * The Search panel is composed of an input field, and a panel that displays the related elements:
 *  - themes;
 *  - categories;
 *  - services.
 *  Everything is connected (poke Dirk Gently!): if the user types a text that is part of a category (or a theme), then
 *  all services associated with this category will be displayed too.
 */
export const Search: FC<ISearchProps> = ({ header, showIt, onClose }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(SEARCH_DEFAULT_WIDTH);

  const { formatMessage } = useIntl();
  const context = useGlobalContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showIt && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const switchVisibility = (forceShow: boolean): void => {
    if (visible && !forceShow) {
      setVisible(false);
      if (onClose) {
        onClose();
      }
      return;
    }

    if (!visible && forceShow) {
      setVisible(true);
      setWidth(inputRef.current ? inputRef.current.offsetWidth : 540);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: Event): void => {
      if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
        switchVisibility(false);
      }
    };

    if (visible) {
      // Panel is visible, we add the event listener on click / touchend.
      document.addEventListener('click', handleOutsideClick, false);
      document.addEventListener('touchend', handleOutsideClick, false);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
      document.removeEventListener('touchend', handleOutsideClick, false);
    };
  }, [elementRef, visible, switchVisibility]);

  const valueChanged = (e: any): void => {
    const changedValue = e.target.value;
    setValue(changedValue);
    debouncedPiwik(changedValue);
  };

  const categoryChanged = (category?: string): void => {
    setSelectedCategory(category);
  };

  /**
   * Do we keep the element considering the user input on search field?
   * Some elements should be considered by their translation, other just by their direct text (such as service name).
   */
  const keepElement = (
    elt: string,
    withTranslation: boolean,
    translationKey?: string
  ): boolean => {
    const txt = withTranslation ? formatMessage({ id: translationKey || elt }) : elt;
    return txt.toLowerCase().indexOf(value.toLowerCase()) > -1;
  };

  const getTermIndexInServiceInfos = (service: IService): number => {
    let index = service.name
      .toLowerCase()
      .indexOf(value.toLowerCase());
    if (index < 0) {
      index = service.initials
        .toLowerCase()
        .indexOf(value.toLowerCase());
      if (index < 0) {
        index = Number.MAX_SAFE_INTEGER;
      }
    }
    return index;
  };

  const filteredCategories: string[] = [];

  const filteredThemes: string[] = context.themes
    .filter((t: IServiceTheme) => keepElement(`theme.${t.id}`, true))
    .sort(sortThemes)
    .map(t => t.id);
  const themesFromFilteredServices: string[] = [];

  const sortedCategories = Object.keys(context.categories).sort(
    sortCategories()
  );
  sortedCategories.map((cat: string) => {
    if (keepElement(`category.${cat}`, true) &&
      filteredCategories.indexOf(cat) === -1) {
      filteredCategories.push(cat);
    }
  });
  filteredCategories.sort(sortCategories());

  // Filtered services: services from filtered from user input & selected tags;
  // Filtered categories: categories of filtered services + categories filtered on user input.
  const filteredServices = context.services
    .filter((s: IService) => {
      // Exclusion #1: the service is not part of the selected category if any...
      if (selectedCategory && s.category !== selectedCategory) {
        return false;
      }
      // Inclusion #1: the user input matches the service name
      if (
        keepElement(`service.${s.name}`, false) ||
        keepElement(`service.${s.initials}`, false)
      ) {
        if (filteredCategories.indexOf(s.category) === -1) {
          filteredCategories.push(s.category);
        }
        for (const th of s.themes) {
          if (themesFromFilteredServices.indexOf(th) === -1) {
            themesFromFilteredServices.push(th);
          }
        }
        return true;
      }
      // Inclusion #2: the service is kept if one of its theme is filtered by the user input...
      for (const th of s.themes) {
        if (filteredThemes.indexOf(th) > -1) {
          return true;
        }
      }
      // Inclusion #2bis: the service is kept if it is part of one corporate theme
      for (const corpoTheme of filteredThemes) {
        if (isServiceForCorporateThemes(corpoTheme, s)) {
          return true;
        }
      }

      // Inclusion #3: the service is part of a filtered category.
      if (filteredCategories.indexOf(s.category) > -1) {
        return true;
      }

      // So if we are here, we were not able to "save" this service for the search result...
      return false;
    })
    .sort(
      (s1: IService, s2: IService) =>
        getTermIndexInServiceInfos(s1) -
        getTermIndexInServiceInfos(s2)
    )
    .slice(0, SEARCH_MAX_SERVICES_DISPLAYED);

  for (const th of themesFromFilteredServices) {
    if (filteredThemes.indexOf(th) === -1) {
      filteredThemes.push(th);
    }
  }

  const selectedCategoryLabel = formatMessage({
    id: selectedCategory ? `category.${selectedCategory}` : 'search.all',
  });

  return (
    <div
      className={`form-group ${header ? 'mb-0' : ''}`}
      ref={elementRef}
    >
      <div className={`input-group ${header ? 'input-group-lg' : 'input-group-xl'}`}>
        <div className="input-group-prepend input-group-merged text-secondary display-4">
          <i className="icon">search</i>
        </div>
        <FormattedMessage id="search.placeholder">
          {msg => (
            <>
              <label className="sr-only" htmlFor="search-input">
                {msg}
              </label>
              <input
                ref={inputRef}
                id="search-input"
                type="text"
                className={'form-control form-control-xl'}
                placeholder={`${msg}`}
                name="user-input"
                onChange={valueChanged}
                onFocus={() => switchVisibility(true)}
                value={value}
              />
            </>
          )}
        </FormattedMessage>

        <div
          className="input-group-append input-group-merged d-none d-sm-flex"
          style={{ zIndex: 1024 }}
        >
          <div className="btn-group">
            <BasicDropdown
              label={selectedCategoryLabel}
              additionalClasses="btn h-100 p-0"
              linkAdditionalClasses="h-100 w-100 px-2 category-selector"
              hideOnClick
              onVisibilityChanged={dropdownVisible =>
                switchVisibility(!dropdownVisible)
              }
            >
              <FormattedMessage id="search.all">
                {txt => (
                  <button
                    type="button"
                    onClick={() => categoryChanged()}
                    className="btn btn-link dropdown-item"
                  >
                    {txt}
                  </button>
                )}
              </FormattedMessage>
              {sortedCategories.map((cat: string) => (
                <FormattedMessage
                  id={`category.${cat}`}
                  key={`category-selection-${cat}`}
                >
                  {txt => (
                    <button
                      type="button"
                      id={`selected-search-category-label-${cat}`}
                      onClick={() => categoryChanged(cat)}
                      className="dropdown-item btn btn-link"
                    >
                      {txt}
                    </button>
                  )}
                </FormattedMessage>
              ))}
            </BasicDropdown>
          </div>
        </div>

        <div
          className={`search-panel shadow-xl dropdown-menu dropdown-menu-right p-4 ${visible && 'visible show'}`}
          style={{ width: `${width}px`, zIndex: 1023 }}
          aria-labelledby="selected-search-category-label"
        >
          {/* Quick links: themes & categories */}
          {(filteredThemes.length > 0 ||
            (selectedCategory === null &&
              filteredCategories.length > 0)) && (
              <div className="text-secondary mt-3 mb-2">
                <FormattedMessage id="search.quick-links" />
              </div>
            )}
          {filteredThemes.map((theme: string) => (
            <Link
              to={`/${context.language}/theme/${theme}`}
              key={`search-theme-${theme}`}
              className="badge badge-discreet-primary badge-lg badge-prepend-square mr-1 mb-2 py-2"
            >
              <FormattedMessage id={`theme.${theme}`} />
            </Link>
          ))}
          {filteredCategories.map(category =>
            category ? (
              <Link
                to={`/${context.language}/category/${category}`}
                key={category}
              >
                <WrappedFormattedMessage
                  id={`category.${category}`}
                  tag="span"
                  className="badge badge-discreet-primary badge-lg badge-prepend-square mr-1 mb-2 py-2 text-capitalize"
                />
              </Link>
            ) : null
          )}

          {/* Services */}
          {filteredServices.length > 0 && (
            <div className="text-secondary mt-3 mb-2">
              <FormattedMessage
                id={`search.${value.length > 0 ? 'suggested' : 'featured'
                  }.services`}
              />
            </div>
          )}
          {filteredServices.map(service => (
            <div className="mb-3" key={service.serviceKey}>
              <Link
                to={`/${context.language}/service/${servicePath(
                  service
                )}`}
              >
                <ServiceInitialsBox service={service} />
                <span style={{ fontSize: '1rem' }}>
                  <span className="mr-1 text-primary font-weight-medium">
                    {service.name}
                  </span>
                  {service.category ? (
                    <>
                      <FormattedMessage id="search.in" />
                      <WrappedFormattedMessage
                        id={`category.${service.category}`}
                        tag="span"
                        className="ml-1 text-capitalize text-secondary"
                      />
                    </>
                  ) : null}
                </span>
              </Link>
              <ServiceAccessButton
                service={service}
                className="float-right d-none d-sm-block"
                requestAccessClassName="float-right d-none d-sm-block"
              />
            </div>
          ))}
          {filteredThemes.length === 0 &&
            filteredCategories.length === 0 &&
            filteredServices.length === 0 && (
              <div className="mb-3">
                <FormattedMessage id="search.no-result" />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
