import * as React from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-mini-footer': any;
    }
  }
}
type MiniFooterContainerClass = 'container' | 'container-fluid';
interface IMiniFooterProps {
  containerClass?: MiniFooterContainerClass;
}
export const MiniFooter: React.FunctionComponent<IMiniFooterProps> = ({
  containerClass = 'container',
}) => {
  return (
    <sgwt-mini-footer
      id="sgwt-mini-footer"
      type="compact"
      design="dark"
      container={containerClass}
      mode="sg-markets"
      contact-us-by-help-center="sgwt-help-center"
      cookies-consent
      accessibility-compliance="partial"
      accessibility-link="/accessibility-statement.html"
    />
  );
};
