import React, { FC } from 'react';
import DocumentMeta from 'react-document-meta';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGlobalContext } from '../../context/global.context';
import { Header } from '../Header/Header';
import { useNavigate, useLocation } from 'react-router';
import { IAward, IService, IServiceList } from '../../data/data.types';
import { Link } from 'react-router-dom';
import { ServiceHeading } from './ServiceHeading';
import {
  getPageMetadata,
  logger,
  servicePath,
} from '../../services/utils.service';
import { MiniFooter } from '../Header/MiniFooter';
import { AwardList } from '../Awards/AwardsList';
import { HelpCenter } from '../HelpCenter/HelpCenter';
import { RelatedServicesList } from './RelatedServicesList';
import { ServiceActions } from './ServiceActions';
import { ServiceScreenshots } from './ServiceScreenshots';
import { ResponsiveText } from '../Common/ResponsiveText';

import './ServicePage.scss';

export const ServicePage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const context = useGlobalContext();

  const getServiceFromPath = (): IService | null => {
    const path = location.pathname.toLowerCase().split('/');
    const key = path[path.length - 1];
    for (const service of context.services) {
      if (servicePath(service) === key) {
        return service;
      }
    }
    return null;
  };

  const language = context.language;

  const service = getServiceFromPath();
  if (!service) {
    logger.warn('Service does not exist');
    navigate(`/${language}`);
    return null;
  }
  if (service.isInternal && (!context.user || context.viewAsClient)) {
    logger.warn(`The user is not allowed to see the service "${service.serviceKey}".`);
    navigate(`/${language}`);
  }

  const awards = context.awards.filter(
    (a: IAward) => a.services.indexOf(service.serviceKey) > -1
  );
  const meta = getPageMetadata(
    formatMessage(
      { id: 'global.title.service' },
      { service: service.name }
    )
  );

  return (
    <DocumentMeta {...meta}>
      <header>
        <Header highlightedCategory={service.category} />
      </header>
      <main className="info-content service-page">
        <div className="container-fluid px-0">
          <div className="container">
            {/* Breadcrumb */}
            <nav aria-label="breadcrumb" className="d-none d-sm-block">
              <ol className="breadcrumb mt-2 mb-4 fs-14">
                <li className="breadcrumb-item">
                  <Link to={`/${context.language}/`}>SG Markets</Link>
                </li>
                {service.category ? (
                  <li className="breadcrumb-item">
                    <Link
                      to={`/${context.language}/category/${service.category}`}
                    >
                      <FormattedMessage
                        id={`category.${service.category}`}
                      />
                    </Link>
                  </li>
                ) : null}
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  {service.name}
                </li>
              </ol>
            </nav>

            <ServiceHeading service={service} />
          </div>

          <ServiceScreenshots service={service} />

          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-9">
                <ResponsiveText
                  tagName="h4"
                  text="service.description"
                  translate
                  className="mb-3"
                  mobileClasses="display-5"
                />
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: service.text[language][0],
                    }}
                  />
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: service.text[language][1],
                    }}
                  />
                </p>
              </div>

              <div className="col-12 col-sm-9">
                <ResponsiveText
                  tagName="h4"
                  text="service.features"
                  translate
                  className="mt-5 mb-3"
                  mobileClasses="display-5"
                />
                <div className="row">
                  {service.list.map(
                    (item: IServiceList, index: number) => (
                      <div
                        className="col-12 col-sm-6 mb-4"
                        key={`icon-${index}`}
                      >
                        <div className="row">
                          <div className="d-none d-sm-block col-sm-2">
                            {item.icon &&
                              item.icon.indexOf('pe-') === 0 ? (
                              <i className={`pe-3x ${item.icon}`} />
                            ) : (
                              <i className="icon icon-xl">
                                {item.icon}
                              </i>
                            )}
                          </div>
                          <div className="col-12 col-sm-9">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item[language],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          {awards.length > 0 ? (
            <div className="container mb-5">
              <ResponsiveText
                tagName="h4"
                text="service.achievements"
                translate
                className="mt-5 mb-3"
                mobileClasses="display-5"
              />
              <AwardList awards={awards} />
            </div>
          ) : null}

          <div className="d-sm-none container mt-5">
            <ServiceActions service={service} />
          </div>

          <RelatedServicesList service={service} />
        </div>
      </main>
      <span className="d-none">{service.serviceKey}</span>

      <HelpCenter />
      <footer>
        <MiniFooter />
      </footer>
    </DocumentMeta>
  );
};
