import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IService } from '../../data/data.types';
import { servicePath } from '../../services/utils.service';
import { useGlobalContext } from '../../context/global.context';
import { ServiceInitialsBox } from '../Common/ServiceInitialsBox';

interface IServicesColumnProps {
  services: IService[];
}

export const ServicesColumn: FC<IServicesColumnProps> = ({ services }) => {
  const { language } = useGlobalContext();
  return (
    <>
      {services
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(service => (
          <div className="mb-2" key={service.serviceKey}>
            <Link to={`/${language}/service/${servicePath(service)}`} className="text-primary no-wrap">
              <ServiceInitialsBox service={service} />

              <span className="d-inline text-capitalize">{service.name}</span>
            </Link>
          </div>
        ))}
    </>
  );
};
