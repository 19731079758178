import React, { FC } from 'react';
import DocumentMeta from 'react-document-meta';
import { useIntl } from 'react-intl';
import { useGlobalContext } from '../../context/global.context';
import { Header } from '../Header/Header';
import { useLocation } from 'react-router';
import { getPageMetadata } from '../../services/utils.service';
import { MiniFooter } from '../Header/MiniFooter';
import { HelpCenter } from '../HelpCenter/HelpCenter';
import { HeadBannerSection } from './Sections/HeadBanner';
import { CategoriesSection } from './Sections/Categories';
import { DiscoverSection } from './Sections/Discover';

export const ThemePage: FC = () => {
  const { formatMessage } = useIntl();
  const context = useGlobalContext();
  const location = useLocation();
  const path = location.pathname.toLowerCase().split('/');
  const theme = context.getTheme(path[path.length - 1]);

  if (!theme) {
    return null;
  }

  const meta = getPageMetadata(
    formatMessage(
      { id: 'global.title.theme' },
      {
        theme: formatMessage({
          id: `theme.${theme.id}`,
        }),
      }
    )
  );

  const highlightedCategory = theme.isMarkets
    ? 'markets'
    : 'sg-markets';

  return (
    <DocumentMeta {...meta}>
      <header>
        <Header highlightedCategory={highlightedCategory} />
      </header>
      <main className="container-fluid info-content theme-page">
        <HeadBannerSection theme={theme} />
        <CategoriesSection theme={theme} />
        <DiscoverSection theme={theme} />
      </main>
      <HelpCenter />
      <footer>
        <MiniFooter />
      </footer>
    </DocumentMeta>
  );
};
