import React, { FC, PropsWithChildren  } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-web-analytics': any;
    }
  }
}

const url = process.env.REACT_APP_PIWIK_SERVER;
const siteId = process.env.REACT_APP_PIWIK_SITE_ID;

const MatomoTrackerProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <sgwt-web-analytics site-id={siteId} base-url={url} />
      {children}
    </>
  );
};

export default MatomoTrackerProvider;
