import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { IGlobalContext, useGlobalContext } from 'context/global.context';
import { IService } from '../../data/data.types';
import { trackEvent } from '../../services/piwik.service';
import { logger } from '../../services/utils.service';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgmarkets-request-access': any;
    }
  }
}

export interface IServiceAccessButtonProps {
  className?: string;
  requestAccessClassName?: string;
  requestAccessClassBtnName?: string;
  service: IService;
}

export interface IServiceAccessButtonState {
  error: Error | null;
}

export const ServiceAccessButton: FC<IServiceAccessButtonProps> = (props) => {
  const context = useGlobalContext();
  return <ServiceAccessButtonContext {...props} context={context} />;
};

interface IServiceAccessButtonContextProps extends IServiceAccessButtonProps {
  context: IGlobalContext,
}

const ServiceAccessButtonContext = class extends React.Component<IServiceAccessButtonContextProps, IServiceAccessButtonState> {
  constructor(props: IServiceAccessButtonContextProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: object) {
    logger.debug(error, errorInfo);
    this.setState({ error });
  }

  redirectToAccess = () => {
    trackEvent('request-access-fallback', this.props.service.serviceKey);
    window.location.href = `/${this.props.context.language}/request-access?returnurl=${this.props.service.url}&sourceurl=${window.location.hostname}`;
  };

  render() {
    const {
      context,
      className,
      requestAccessClassName,
      requestAccessClassBtnName,
      service,
    } = this.props;
    const hasAccess =
      context.accessibleServices.indexOf(service.serviceKey) > -1;
    const link = service.url;
    const text = 'service.access';
    const requestAccessWidgetSite = transformServiceToRequestAccessWidgetSite(
      service
    );
    return (
      <>
        {hasAccess && (
          <a
            href={link}
            onClick={() => trackEvent('access', service.serviceKey)}
            className={`btn btn-socgen px-3 no-wrap ${className}`}
          >
            <FormattedMessage id={text} />
          </a>
        )}
        {!hasAccess && !this.state.error && (
          <sgmarkets-request-access
            onClick={() => trackEvent('request-access', service.serviceKey)}
            class={requestAccessClassName}
            classbtn={`btn btn-discreet-socgen px-3 no-wrap ${requestAccessClassBtnName}`}
            site={requestAccessWidgetSite}
            url={link}
            source-url={window.location.hostname}
            source-context="new-info"
          />
        )}
        {!hasAccess && this.state.error && (
          <button
            onClick={this.redirectToAccess}
            className={`btn btn-discreet-socgen px-3 no-wrap ${requestAccessClassBtnName}`}
          >
            Request access
          </button>
        )}
      </>
    );
  }
};

const transformServiceToRequestAccessWidgetSite = (
  service: IService
): string => {
  const { url, serviceKey, popsIdentifier, name } = service;
  return JSON.stringify({
    url,
    serviceKey: (serviceKey || '__EMPTY__').toLocaleUpperCase(),
    popsIdentifier,
    name,
  });
};
