import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntlExtended } from 'common/reactIntlExtended';
import { ServiceSelectOptions } from './ServiceSelectOptions';
import { SelectForm, TextareaForm, CheckboxForm } from '../inputs';
import { BaseRequestFormContent } from '../models';

type ServiceDetailsFormContext = Pick<BaseRequestFormContent, 'serviceKey' | 'displayServiceDetails' | 'serviceDetails'>;

interface IServiceDetailsFormPartProps {
    noTitle?: boolean;
}

export const ServiceDetailsFormPart: FC<IServiceDetailsFormPartProps> = ({ noTitle }) => {
    const { t } = useIntlExtended();
    const { register, watch, resetField, formState: { errors, isSubmitting } } = useFormContext<ServiceDetailsFormContext>();
    const displayServiceDetails = watch('displayServiceDetails');

    return <>
        {!noTitle && <>
            <p className="text-xlarge font-weight-medium">
                {t('request-access.form.service', { count: 1 })}
            </p>
            <p>
                {t('request-access.form.service.subtitle')}&nbsp;
                <a href="/">SG Markets Info&nbsp;<i className="icon">open_in_new</i></a>
            </p>
        </>}
        <SelectForm
            labelId="request-access.form.service.name"
            register={register}
            errors={errors}
            name="serviceKey"
            disabled={displayServiceDetails || isSubmitting}
        >
            <ServiceSelectOptions />
        </SelectForm>
        <CheckboxForm
            labelId="request-access.form.service.notfound"
            register={register}
            registerOptions={{
                onChange: () => {
                    resetField('serviceKey');
                    resetField('serviceDetails');
                },
            }}
            errors={errors}
            name="displayServiceDetails"
            disabled={isSubmitting}
        />
        {displayServiceDetails && (
            <TextareaForm
                labelId="request-access.form.service.details"
                placeholderId="request-access.form.service.details.placeholder"
                register={register}
                errors={errors}
                name="serviceDetails"
                rows={2}
                disabled={isSubmitting}
            />
        )}
    </>;
};
