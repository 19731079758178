import * as React from 'react';
import { ToastContentProps } from 'react-toastify';

type ToastMessageProps = Partial<ToastContentProps> & {
  title?: string;
  message?: string;
};

export const ToastMessage: React.FC<ToastMessageProps> = ({
  title,
  message,
  closeToast,
  toastProps,
}) => {
  let toastTitle = title || 'Information';
  if (!title && toastProps) {
    switch (toastProps.type) {
      case 'error':
        toastTitle = 'Error';
        break;

      case 'warning':
        toastTitle = 'Warning';
        break;

      case 'success':
        toastTitle = 'Success';
        break;

      default:
        toastTitle = 'Information';
        break;
    }
  }

  return (
    <>
      <div className="toast-header">
        <strong>{toastTitle}</strong>
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
          onClick={closeToast}
        >
          <i className="icon icon-light">close</i>
        </button>
      </div>
      {message && <div className="toast-body text-primary">{message}</div>}
      <div className="toast-footer">
        <span className="text-secondary font-weight-medium">
          {new Date().toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </span>
      </div>
    </>
  );
};
