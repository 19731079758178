import React, { ReactNode, useId } from 'react';
import { useIntlExtended } from 'common/reactIntlExtended';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors } from 'react-hook-form';
import { getCustomErrorComponent } from '../errors/customErrorComponent';
import classNames from 'classnames';

interface ITextareaFormProps<T extends FieldValues> {
    labelId: string;
    placeholderId?: string;
    name: FieldPath<T>;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    rows?: number;
    disabled?: boolean;
}

export const TextareaForm = <T extends FieldValues>({ labelId, name, register, errors, placeholderId, disabled, rows }: ITextareaFormProps<T>): ReactNode => {
    const { t } = useIntlExtended();
    const inputId = useId();

    const error = errors[name]?.message;
    const inputClassName = classNames('form-control form-control-xl ', {
        'is-invalid': error,
    });

    return <div className="form-group mb-4">
        <label className="mb-1" htmlFor={inputId}>
            {t(labelId)}
        </label>
        <textarea
            id={inputId}
            className={inputClassName}
            placeholder={placeholderId && t(placeholderId)}
            disabled={disabled}
            rows={rows}
            {...register(name)}
        />
        {typeof error === 'string' && <div className="invalid-feedback">
            {getCustomErrorComponent(error) ?? t(`${error}`)}
        </div>}
    </div>;
};
