import React, { FC } from 'react';
import { Navigate, useParams } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { HomePage } from '../Home/HomePage';
import { IGlobalContext, LANGUAGE } from '../../context/global.context';
import { ServicesProvider } from './ServicesProvider';
import { CategoryPage } from '../Category/CategoryPage';
import { RequestAccessPage } from '../RequestAccess';
import { ServicePage } from '../Service/ServicePage';
import { ScrollToTop } from './ScrollToTop';
import { NotFound } from '../Error/NotFound';
import { ThemePage } from '../Theme/ThemePage';
import { IServiceTheme } from '../../data/data.types';
import { servicePath } from '../../services/utils.service';
import { WaitForDataComponent } from './WaitForDataComponent';
import MatomoTrackerProvider from '../Common/MatomoTrackerProvider';
import SgwtConnectProvider from '../Common/SgwtConnectProvider';
import { UnsubscribeComponent } from '../Subscription/UnsubscribeComponent';
import { UnsubscribeValidatedComponent } from '../Subscription/UnsubscribeValidatedComponent';
import { toast, ToastContainer } from 'react-toastify';
import classNames from 'classnames';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import en from './locales/en.json';
import fr from './locales/fr.json';

export const App: FC = () => (
  <SgwtConnectProvider>
    <MatomoTrackerProvider>
      <BrowserRouter>
        <ServicesProvider>
          {(context: IGlobalContext) => {
            const messages = context.language === LANGUAGE.FR ? fr : en;
            return <IntlProvider locale={context.language} messages={messages}>
              <ScrollToTop>
                <Routes>
                  {/*
                    If we got an error during data retrieval, we use this component to display the message to the user.
                    */}
                  {context.dataError && (
                    <Route path="*" element={<WaitForDataComponent />} />
                  )}

                  {/*
                    When the data is not loaded yet, all routes (except for the homepages) are handled by this component
                    that basically waits the data to be loaded. When the data has been received, the App is re-rendered
                    and this Route is not created anymore.
                    */}
                  {!context.dataError && !context.dataLoaded && (
                    <Route path="*" element={<WaitForDataComponent />} />
                  )}

                  {!context.dataError && context.dataLoaded && (<>
                    <Route path="/en" element={<HomePage />} />
                    <Route path="/fr" element={<HomePage />} />

                    <Route
                      path="/"
                      element={<Navigate replace to={`/${context.language}`} />}
                    />

                    {/* Routes for services */}
                    {context.services.map(service => (
                      <Route
                        key={`route-service-${service.serviceKey}-fr`}
                        path={`/en/service/${servicePath(service)}`}
                        element={<ServicePage />}
                      />
                    ))}
                    {context.services.map(service => (
                      <Route
                        key={`route-service-${service.serviceKey}-fr`}
                        path={`/fr/service/${servicePath(service)}`}
                        element={<ServicePage />}
                      />
                    ))}

                    {/* Routes for main categories */}
                    {Object.keys(context.categories).map(category => (
                      <Route
                        key={`route-category-${category}-en`}
                        path={`/en/category/${category}`}
                        element={<CategoryPage />}
                      />
                    ))}
                    {Object.keys(context.categories).map(category => (
                      <Route
                        key={`route-category-${category}-fr`}
                        path={`/fr/category/${category}`}
                        element={<CategoryPage />}
                      />
                    ))}

                    {/* Routes for themes */}
                    {context.themes.map((theme: IServiceTheme) => (
                      <Route
                        key={`route-theme-${theme.id}-en`}
                        path={`/en/theme/${theme.id}`}
                        element={<ThemePage />}
                      />
                    ))}
                    {context.themes.map((theme: IServiceTheme) => (
                      <Route
                        key={`route-theme-${theme.id}-fr`}
                        path={`/fr/theme/${theme.id}`}
                        element={<ThemePage />}
                      />
                    ))}
                  </>
                  )}

                  {/* Route For Request Access From */}
                  <Route
                    path={'/en/request-access'}
                    element={<RequestAccessPage />}
                  />
                  <Route
                    path={'/fr/request-access'}
                    element={<RequestAccessPage />}
                  />

                  {/* Route For Request Demo From */}
                  <Route
                    path={'/en/request-demo'}
                    element={<RequestAccessPage demoForm />}
                  />
                  <Route
                    path={'/fr/request-demo'}
                    element={<RequestAccessPage demoForm />}
                  />


                  {/* Route For Client Subscription List */}
                  <Route
                    path="/en/subscription/unsubscribe/:code/"
                    element={<UnsubscribeComponent />}
                  />
                  <Route
                    path="/fr/subscription/unsubscribe/:code/"
                    element={<UnsubscribeComponent />}
                  />

                  <Route
                    path="/subscription/unsubscribe/:code/"
                    element={<UnsubscribeRedirect />}
                  />

                  <Route
                    path="/en/subscription/unsubscribe-validated"
                    element={<UnsubscribeValidatedComponent />}
                  />
                  <Route
                    path="/fr/subscription/unsubscribe-validated"
                    element={<UnsubscribeValidatedComponent />}
                  />

                  <Route
                    path="/subscription/unsubscribe-validated"
                    element={<Navigate replace to="/en/subscription/unsubscribe-validated" />}
                  />

                  <Route
                    path="*"
                    element={<NotFound />}
                  />
                </Routes>
              </ScrollToTop>
            </IntlProvider>;
          }}
        </ServicesProvider>
      </BrowserRouter>
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        closeButton={false}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName={context =>
          classNames('toast fade show', {
            'toast-danger': context && context.type === 'error',
            'toast-info': context && context.type === 'info',
            'toast-success': context && context.type === 'success',
            'toast-warning': context && context.type === 'warning',
            'toast-primary': context && context.type === 'default',
          })
        }
        bodyClassName={() => ''}
        progressClassName={context =>
          classNames(context && context.defaultClassName, {
            'bg-danger': context && context.type === 'error',
            'bg-info': context && context.type === 'info',
            'bg-success': context && context.type === 'success',
            'bg-warning': context && context.type === 'warning',
            'bg-primary': context && context.type === 'default',
          })
        }
        progressStyle={{ height: '3px' }}
      />
    </MatomoTrackerProvider>
  </SgwtConnectProvider>
);

const UnsubscribeRedirect: FC = () => {
  const { code } = useParams();
  return <Navigate replace to={`/en/subscription/unsubscribe/${encodeURIComponent(code || '')}/`} />;
};