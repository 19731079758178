import axios from 'axios';
import React, { FC, useState } from 'react';
import { useIntlExtended } from 'common/reactIntlExtended';
import { useLocation } from 'react-router';
import { parseQueryString } from '../../common/utils';
import { useGlobalContext } from '../../context/global.context';
import { logger } from '../../services/utils.service';
import { MainComponent, SuccessComponent } from './RequestAccessComponent';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ServiceDetailsFormPart } from './form/ServiceDetailsFormPart';
import { CompanyFormPart } from './form/CompanyFormPart';
import { RepresentativeFormPart } from './form/RepresentativeFormPart';
import { PersonalFormPart } from './form/PersonalFormPart';
import { shareinboxCustomError } from './errors/customErrorComponent';
import {
    IRequestAccessContent, FRANFINANCE_SERVICE_KEY, INSIGHT_SERVICE_KEY,
    DEFAULT_SGM_SERVICE_NAME, PersonalUserEmailRegex, RepresentativeEmailRegex,
    SHAREINBOX_SERVICE_KEY,
} from './models';

const apiRequestAccessBase = process.env.REACT_APP_API_REQUEST_ACCESS_BASE;

interface IRequestAccessFormProps {
    initialServiceKey?: string;
    initialServiceName?: string;
}

const schema = () => yup
    .object({
        displayServiceDetails: yup.boolean().required(),

        serviceKey: yup.string()
            .when('displayServiceDetails', {
                is: true,
                then: (schema) => schema.notRequired(),
                otherwise: (schema) =>
                    schema
                        .required('request-access.required')
                        .test('shareinbox', shareinboxCustomError, x => x !== SHAREINBOX_SERVICE_KEY),
            }),

        serviceDetails: yup.string()
            .when('displayServiceDetails', {
                is: true,
                then: (schema) => schema.required('request-access.required'),
                otherwise: (schema) => schema.notRequired(),
            }),

        country: yup.string().required('request-access.required'),
        companyName: yup.string().required('request-access.required'),
        companyAddress: yup.string().required('request-access.required'),
        siren: yup.string()
            .matches(/^[0-9]{9}$/, { excludeEmptyString: true, message: 'request-access.form.company.siren.error' })
            .when('serviceKey', {
                is: (serviceKey: string) => serviceKey === FRANFINANCE_SERVICE_KEY,
                then: (schema) => schema.required('request-access.required'),
                otherwise: (schema) => schema.notRequired(),
            }),
        clientReference: yup.string()
            .when('serviceKey', {
                is: (serviceKey: string) => serviceKey === FRANFINANCE_SERVICE_KEY,
                then: (schema) => schema.required('request-access.required'),
                otherwise: (schema) => schema.notRequired(),
            }),

        firstName: yup.string().required('request-access.required').max(50, 'request-access.too-long'),
        lastName: yup.string().required('request-access.required').max(50, 'request-access.too-long'),
        jobTitle: yup.string().required('request-access.required'),

        email: yup.string()
            .email('request-access.form.details.email.error')
            .required('request-access.required')
            .max(100, 'request-access.too-long')
            .when('serviceKey', {
                is: (serviceKey: string) => serviceKey !== FRANFINANCE_SERVICE_KEY,
                then: (schema) => schema.matches(PersonalUserEmailRegex, { excludeEmptyString: true, message: 'request-access.form.details.email.error' }),
            }),

        professionalPhoneNumber: yup.string()
            .when('serviceKey', {
                is: (serviceKey: string) => serviceKey === FRANFINANCE_SERVICE_KEY,
                then: (schema) => schema.required('request-access.required'),
                otherwise: (schema) => schema.notRequired(),
            }),

        isMifid2Eligible: yup.string()
            .when('serviceKey', {
                is: (serviceKey: string) => serviceKey === INSIGHT_SERVICE_KEY,
                then: (schema) => schema.required('request-access.required').oneOf(['yes', 'no']),
                otherwise: (schema) => schema.notRequired(),
            }),

        hasNotRepresentativeEmail: yup.boolean().required(),

        representativeEmail: yup.string()
            .email('request-access.form.representative.email.error')
            .matches(RepresentativeEmailRegex, { excludeEmptyString: true, message: 'request-access.form.representative.email.error' })
            .max(100, 'request-access.too-long')
            .when(['serviceKey', 'hasNotRepresentativeEmail'], {
                is: (serviceKey: string, hasNotRepresentativeEmail: boolean) =>
                    serviceKey === FRANFINANCE_SERVICE_KEY || hasNotRepresentativeEmail,
                then: (schema) => schema.notRequired(),
                otherwise: (schema) => schema.required('request-access.required'),
            }),

        representativeDetails: yup.string()
            .when(['serviceKey', 'hasNotRepresentativeEmail'], {
                is: (serviceKey: string, hasNotRepresentativeEmail: boolean) =>
                    serviceKey !== FRANFINANCE_SERVICE_KEY && hasNotRepresentativeEmail,
                then: (schema) => schema.required('request-access.required'),
                otherwise: (schema) => schema.notRequired(),
            }),
    });

export const RequestAccessForm: FC<IRequestAccessFormProps> = ({ initialServiceKey, initialServiceName }) => {
    const location = useLocation();

    const { t } = useIntlExtended();
    const context = useGlobalContext();

    const [success, setSuccess] = useState(false);
    const [apiErrors, setApiErrors] = useState<string[]>([]);
    const hasInitialService = !!initialServiceKey;

    const getOrder = (value: number): number => hasInitialService ? value : value + 1;

    const handleSubmit: SubmitHandler<IRequestAccessContent> = (data) => {
        const parsedQueryString = parseQueryString(location.search);
        const config = {
            url: `${apiRequestAccessBase}/requests/form`,
            method: 'POST',
            data: {
                serviceKey: data.serviceKey || '__EMPTY__',
                details: data.displayServiceDetails ? data.serviceDetails : undefined,

                country: data.country,
                companyName: data.companyName,
                companyAddress: data.companyAddress,
                siren: data.siren,
                clientReference: data.clientReference,

                firstName: data.firstName,
                lastName: data.lastName,
                jobTitle: data.jobTitle,
                email: data.email,
                professionalPhoneNumber: data.professionalPhoneNumber,

                isMifid2Eligible: isInsight ? (data.isMifid2Eligible === 'yes') : undefined,

                representativeEmail: !data.hasNotRepresentativeEmail ? data.representativeEmail : null,
                representativeDetails: data.hasNotRepresentativeEmail ? data.representativeDetails : null,

                requestFormLanguage: context.language,
                returnUrl: parsedQueryString.returnurl || '',
                sourceUrl: parsedQueryString.sourceurl || '',
                sourceContext: parsedQueryString.sourcecontext || '',
            },
            validateStatus: () => true,
            timeout: 15000,
            headers: {},
        };
        return axios(config)
            .then(({ data, status }) => {
                if (status === 200) {
                    setSuccess(true);
                    window.scroll(0, 0);
                } else if (status === 400) {
                    logger.warn('Bad request error on form', JSON.stringify(data));
                    setApiErrors(formatApiBadRequest(data));
                } else {
                    logger.error('Unknown error on form', JSON.stringify(data));
                    setApiErrors([t('request-access.form.submit.error')]);
                }
            })
            .catch(error => {
                logger.error('Not able to fill form', JSON.stringify(error));
                setApiErrors([t('request-access.form.submit.error')]);
            });
    };

    const formMethods = useForm<IRequestAccessContent>({
        mode: 'onBlur',
        resolver: yupResolver(schema()),
        defaultValues: {
            serviceKey: initialServiceKey,
            displayServiceDetails: false,
            hasNotRepresentativeEmail: false,
        },
    });

    const { watch, formState: { isSubmitting } } = formMethods;

    const serviceKey = watch('serviceKey');

    const isInsight = serviceKey === INSIGHT_SERVICE_KEY;
    const isFranfinance = serviceKey === FRANFINANCE_SERVICE_KEY;

    if (success) {
        return <MainComponent>
            <SuccessComponent />
        </MainComponent>;
    }

    return (
        <MainComponent>
            <h1 className="display-3 mb-1">{t('request-access')}</h1>
            <h2 className="h4 spacing-mb-2 text-secondary">
                {t('request-access.request-access.fill', { serviceName: initialServiceName ?? DEFAULT_SGM_SERVICE_NAME })}
            </h2>
            <div className="position-relative spacing-mb-5">
                <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                    <FormProvider {...formMethods} >
                        {!hasInitialService && <ServiceDetailsFormPart />}
                        <CompanyFormPart
                            titleOrder={getOrder(1)}
                            showSiren={isFranfinance}
                            showClientReference={isFranfinance}
                        />

                        <PersonalFormPart
                            titleOrder={getOrder(2)}
                            showProfessionalPhoneNumber={isFranfinance}
                            showMifid={isInsight}
                        />

                        {!isFranfinance && <RepresentativeFormPart titleOrder={getOrder(isInsight ? 4 : 3)} />}

                        <button
                            type="submit"
                            className="btn btn-xl btn-block btn-socgen spacing-mt-2"
                            disabled={isSubmitting}
                        >
                            {t('request-access.form.submit')}
                        </button>

                        {apiErrors?.length > 0 && <>
                            <div className="mt-4 alert alert-danger alert-discreet-danger">
                                {apiErrors.map((x, i) => <div key={i}>{x}</div>)}
                            </div>
                        </>}
                    </FormProvider>
                </form>
            </div>
        </MainComponent >
    );
};

const formatApiBadRequest = (data: any): string[] => {
    return Object.keys(data.errors || {}).map(x => data.errors[x]);
};
