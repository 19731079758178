import React, { FC } from 'react';
import { UncontrolledTooltip } from 'reactstrap/lib';
import { Placement } from 'popper.js';

interface IDefaultTooltipProps {
  target: string | HTMLElement | React.RefObject<HTMLElement>;
  text: string;
  placement?: Placement;
}

export const DefaultTooltip: FC<IDefaultTooltipProps> = ({ target, text, placement }) => {
  return (
    <UncontrolledTooltip
      placement={placement || 'right'}
      target={target}
    >
      {text}
    </UncontrolledTooltip>
  );
};
