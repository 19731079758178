import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntlExtended } from 'common/reactIntlExtended';
import { SelectForm, InputForm, TextareaForm } from '../inputs';
import { CountrySelectOptions } from './CountrySelectOptions';
import { IRequestAccessContent } from '../models';

type CompanyFormContext = Pick<IRequestAccessContent, 'country' | 'companyName' | 'companyAddress' | 'siren' | 'clientReference'>;

interface ICompanyFormPartProps {
    titleOrder: number;
    showSiren?: boolean;
    showClientReference?: boolean;
}

export const CompanyFormPart: FC<ICompanyFormPartProps> = ({ titleOrder, showSiren, showClientReference }) => {
    const { t } = useIntlExtended();
    const { register, formState: { errors, isSubmitting } } = useFormContext<CompanyFormContext>();

    return <>
        <p className="text-xlarge font-weight-medium mb-4 spacing-mt-2">
            {t('request-access.form.company', { count: titleOrder })}
        </p>
        <SelectForm
            labelId="request-access.form.company.country"
            register={register}
            errors={errors}
            name="country"
            disabled={isSubmitting}
        >
            <CountrySelectOptions />
        </SelectForm>
        <InputForm
            labelId="request-access.form.company.name"
            placeholderId="request-access.form.company.name.placeholder"
            register={register}
            errors={errors}
            name="companyName"
            disabled={isSubmitting}
        />
        <TextareaForm
            labelId="request-access.form.company.address"
            placeholderId="request-access.form.company.address.placeholder"
            register={register}
            errors={errors}
            name="companyAddress"
            rows={2}
            disabled={isSubmitting}
        />
        {showSiren && <InputForm
            labelId="request-access.form.company.siren"
            placeholderId="request-access.form.company.siren.placeholder"
            helpId="request-access.form.company.siren.tooltip"
            register={register}
            errors={errors}
            name="siren"
            disabled={isSubmitting}
        />}
        {showClientReference && <InputForm
            labelId="request-access.form.company.clientReference"
            placeholderId="request-access.form.company.clientReference.placeholder"
            helpId="request-access.form.company.clientReference.tooltip"
            register={register}
            errors={errors}
            name="clientReference"
            disabled={isSubmitting}
        />}
    </>;
};
