import React, { useState, useEffect, FC, PropsWithChildren } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-connect': any;
    }
  }
}

const SgwtConnectProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  const setupPassiveMode = () => {
    const widget = document && (document.querySelector('sgwt-connect') as any);
    if (widget && widget.setSgwtConnectInstance) {
      setIsReady(true);
    } else {
      setTimeout(setupPassiveMode, 100);
    }
  };

  useEffect(() => {
    setupPassiveMode();
  }, []);

  return <>{isReady && children}</>;
};

export default SgwtConnectProvider;
