import React, { FC } from 'react';
import { useGlobalContext } from '../../context/global.context';
import { FormattedMessage } from 'react-intl';
import { trackEvent } from '../../services/piwik.service';
import { shareServiceLink } from '../../services/utils.service';
import { IService } from '../../data/data.types';

export interface IServiceActionsProps {
  service: IService;
}

export const ServiceActions: FC<IServiceActionsProps> = ({ service }) => {
  const context = useGlobalContext();
  const language = context.language;
  const internalView = context.internalUser && !context.viewAsClient;
  return (
    <>
      {/* screen view */}
      <span className="d-none d-sm-inline-block">
        {internalView && !!service.links.jive && (
          <a
            href={service.links.jive}
            onClick={() => trackEvent('jive', service.serviceKey)}
            className="mx-2"
          >
            <i className="icon">chat</i>
            &nbsp;
            <FormattedMessage id="service.documentation" />
          </a>
        )}
        <a
          href={shareServiceLink(service, language)}
          onClick={() => trackEvent('share', service.serviceKey)}
          className="ml-2"
        >
          <i className="icon">share</i>
          &nbsp;
          <FormattedMessage id="service.share" />
        </a>
      </span>

      {/* mobile view */}
      <div className="d-sm-none row">
        <div className="col-6 pr-1">
          {internalView && !!service.links.jive && (
            <a
              className="btn btn-block btn-lg sgbs-btn-default"
              href={service.links.jive}
              onClick={() => trackEvent('jive', service.serviceKey)}
            >
              <i className="icon mr-1">chat</i>
              <FormattedMessage id="service.documentation" />
            </a>
          )}
        </div>
        <div className={internalView ? 'col-6 pl-1' : 'col-12'}>
          <a
            className="btn btn-block btn-lg sgbs-btn-default"
            href={shareServiceLink(service, language)}
            onClick={() => trackEvent('share', service.serviceKey)}
          >
            <i className="icon mr-1">share</i>
            <FormattedMessage id="service.share" />
          </a>
        </div>
      </div>
    </>
  );
};
