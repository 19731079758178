import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { useIntlExtended } from 'common/reactIntlExtended';

export const SgHeaderComponent: FC = () => (
  <header className="header w-25">
    <a href="/" className="bg-info">
      <img
        className="d-block d-lg-none m-4"
        height="30"
        src="/images/socgen_logo_muted.svg"
        alt="Logo Société Générale"
      />
      <img
        className="d-none d-lg-block m-5"
        height="30"
        src="/images/socgen_logo_full.svg"
        alt="Logo Société Générale"
      />
    </a>
  </header>
);

export const FooterComponent: FC = () => (
  <div className="footer mx-3 mx-lg-4 spacing-mb-xl-1">
    <sgwt-mini-footer
      mode="sg-markets"
      contact-us='{ "mail": "sgmarkets@sgcib.com" }'
    />
  </div>
);

export const SuccessComponent: FC = () => {
  const navigate = useNavigate();
  const { t } = useIntlExtended();
  return (
    <>
      <div className="d-flex align-items-center flex-column w-100">
        <div
          className="rounded-circle bg-success-alpha-md d-flex align-items-center justify-content-center mb-4"
          style={{ width: '3rem', height: '3rem' }}
        >
          <i className="icon icon-md text-success line-height-1"> check </i>
        </div>
        <h2 className="row text-success mb-2">
          {t('request-access.thank-you')}
        </h2>
      </div>
      <p className="text-large text-secondary m-0 text-center">
        {t('request-access.thank-you.description')}
      </p>
      <button
        className="btn btn-xl btn-block btn-flat-secondary btn-text-icon form-group spacing-mt-2"
        onClick={() => navigate('/')}
      >
        <span>{t('request-access.thank-you.back')}</span>
      </button>
    </>
  );
};

export const MainComponent: FC<PropsWithChildren> = ({ children }) => (
  <div className="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-1 justify-content-center position-relative">
    <main id="main-wrapper" className="mx-auto px-4 pb-5">
      <div className="fade-element" />
      {children}
    </main>
  </div>
);
