import { getSgwtConnect } from '../services/api.service';

const INFO_PROXY_BASE = process.env.REACT_APP_INFO_PROXY_BASE;

type BasicType = string | number | Date | boolean | object;

interface IFunctionalLog {
  feature: string;
  logType: 'Functional';
  message: string;
  customProperties?: { [key: string]: object };
}

interface ITechnicalLog {
  level: 'debug' | 'info' | 'warn' | 'error';
  logType: 'Technical';
  message: string;
  customProperties?: { [key: string]: BasicType };
}

interface IPerformanceLog {
  logType: 'Performance';
  feature: string;
  elapsedTime: number;
  message: string;
  customProperties?: { [key: string]: object };
}

const functionalLogs: IFunctionalLog[] = [];
export const logFunctional = async (
  feature: string,
  message: string,
  customProperties?: { [key: string]: object }
) => {
  functionalLogs.push({
    logType: 'Functional',
    feature,
    message,
    customProperties,
  });

  const isSucess = await sendLogs(functionalLogs);
  if (isSucess) {
    functionalLogs.splice(0, functionalLogs.length);
  }
};

const technicalLogs: ITechnicalLog[] = [];
export const logTechnical = async (
  type: 'debug' | 'info' | 'warn' | 'error',
  message: string,
  customProperties?: { [key: string]: object }
) => {
  technicalLogs.push({
    level: type,
    logType: 'Technical',
    message,
    customProperties,
  });

  const isSuccess = await sendLogs(technicalLogs);
  if (isSuccess) {
    technicalLogs.splice(0, technicalLogs.length);
  }
};

const performanceLogs: IPerformanceLog[] = [];
const logPerformance = async (performanceLog: IPerformanceLog) => {
  performanceLogs.push(performanceLog);

  const isSuccess = await sendLogs(performanceLogs);
  if (isSuccess) {
    performanceLogs.splice(0, performanceLogs.length);
  }
};

export class PerformanceMeasureLogger {
  private begin: number;

  public static start(
    feature: string,
    message: string,
    customProperties?: { [key: string]: object }
  ): PerformanceMeasureLogger {
    return new PerformanceMeasureLogger(feature, message, customProperties);
  }

  public stop(): void {
    const elapsedTime = performance.now() - this.begin;
    logPerformance({
      feature: this.feature,
      message: this.message,
      logType: 'Performance',
      elapsedTime,
      customProperties: this.customProperties,
    });
  }

  private constructor(
    private feature: string,
    private message: string,
    private customProperties?: { [key: string]: object }
  ) {
    this.begin = performance.now();
    this.feature = feature;
    this.message = message;
    this.customProperties = customProperties;
  }
}

async function sendLogs(
  logs: IFunctionalLog[] | ITechnicalLog[] | IPerformanceLog[]
): Promise<boolean> {
  let retryCount = 0;
  const jsonBody = JSON.stringify({ log: logs });

  const headers: HeadersInit = [
    ['accept', 'application/json'],
    ['content-type', 'application/json'],
  ];

  const sgConnect = getSgwtConnect();
  const token = sgConnect && sgConnect.getAuthorizationHeader();
  if (token) {
    headers.push(['authorization', token]);
  }

  const url = `${INFO_PROXY_BASE}/v1/clientmonitoring`;

  do {
    const response = await fetch(url, {
      headers,
      method: 'POST',
      body: jsonBody,
    });

    if (!response.ok) {
      retryCount = retryCount + 1;
      await new Promise(resolve =>
        // Retry values:
        // - first time: 5 seconds
        // - second time: 20 seconds
        // - third time: 45 seconds
        setTimeout(resolve, retryCount * retryCount * 5000)
      );
      continue;
    }
    return response.ok;
  } while (retryCount < 3);

  // tslint:disable-next-line: no-console
  console.error(`Cannot send errors to the server after 3 times - url: ${url}`);
  return false;
}
