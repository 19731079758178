import React, { FC } from 'react';
import { ThemeCard } from '../Theme/ThemeCard';
import { ResponsiveComponent } from '../Common/ResponsiveComponent';
import { shuffleArray } from '../../services/utils.service';
import { THEME_PAGE_MAX_HIGHLIGHTED_THEMES } from '../../common/constants';

export interface IHighlightedThemesProps {
  themes: string[];
}

export const HighlightedThemes: FC<IHighlightedThemesProps> = ({ themes }) => {
  if (themes.length === 0) {
    return null;
  }
  const highlightedThemes: string[] = shuffleArray(themes).slice(0, THEME_PAGE_MAX_HIGHLIGHTED_THEMES);
  const screen = highlightedThemes.map(theme =>
    <div className="col-12 col-sm-6" key={`highlighted-theme-${theme}`}>
      <ThemeCard themeId={theme} mode="landscape" />
    </div>
  );
  const mobile = highlightedThemes.map((theme, index) =>
    <div className={`mr-3 ${index === 0 ? 'ml-3' : ''}`} style={{ width: '80vw' }} key={`highlighted-theme-${theme}`}>
      <ThemeCard themeId={theme} mode="landscape" />
    </div>
  );

  return (
    <>
      <ResponsiveComponent
        componentForMobile={mobile}
        componentForScreen={screen} />
      <div className="pt-5 mb-4 border-bottom" />
    </>
  );
};
