import * as React from 'react';
import './Error.scss';
import { FormattedMessage } from 'react-intl';
import { HomeHeader } from '../Header/HomeHeader';

interface IErrorProps {
  code: number;
  title: string;
  message: string;
  imageUrl: string;
  handleTryAgain?: (event: any) => void;
}

export const Error = (props: IErrorProps) => {
  const { code, title, message, imageUrl, handleTryAgain } = props;
  return (
    <>
      <HomeHeader />
      <div className="container mb-5">
        <div className="error d-flex flex-column flex-fill">
          <section className="section section-1 d-flex flex-fill">
            <div className="flex-fill" />
            <div className="flex-fill" />
            <div className="flex-fill bg" />
          </section>
          <section className="section section-2 d-flex justify-content-center">
            <div className="bg d-flex flex-lg-fill flex-column flex-lg-row">
              <div className="pt-5 pb-4 py-lg-0 px-lg-5">
                <span
                  className="d-block d-md-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{
                    fontSize: '56px',
                  }}
                >
                  {code}
                </span>
                <span
                  className="d-none d-md-block d-lg-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{
                    fontSize: '56px',
                    lineHeight: 1.2,
                  }}
                >
                  {code}
                </span>
                <span
                  className="mt-n2 d-none d-lg-inline-block d-xl-none display-2
                            text-outline-socgen text-primary-alt font-weight-medium"
                >
                  {code}
                </span>
                <span
                  className="mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: '96px', overflow: 'hidden' }}
                >
                  {code}
                </span>
              </div>
              <div className="col-lg-5  bg-white d-flex flex-column justify-content-between">
                <div>
                  <h1 className="d-none d-lg-block display-2 font-weight-medium line-height-1 pt-0 pb-3">
                    <FormattedMessage id={title} />
                  </h1>
                  <h1 className="d-lg-none display-3 font-weight-medium line-height-1 py-2">
                    <FormattedMessage id={title} />
                  </h1>
                  <p className="d-lg-none mb-5 h3 font-weight-medium text-secondary line-height-1">
                    <FormattedMessage id={message} />
                  </p>
                  <p className="d-none d-lg-block d-xxl-none display-3 font-weight-medium text-secondary w-75 line-height-1">
                    <FormattedMessage id={message} />
                  </p>
                  <p className="d-none d-xxl-block display-3 font-weight-medium text-secondary w-75 line-height-1">
                    <FormattedMessage id={message} />
                  </p>
                </div>
                <div className="pb-4">
                  {code !== 404 && (
                    <button
                      className="btn btn-xl btn-primary"
                      onClick={handleTryAgain}
                    >
                      <FormattedMessage id="error.tryagain" />
                    </button>
                  )}
                  <a
                    className="btn btn-xl btn-flat-secondary ml-1"
                    href="mailto:sgmarkets@sgcib.com"
                    title="Contact SG Markets"
                  >
                    <FormattedMessage id="error.contactus" />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex-fill px-0 position-relative d-none d-lg-block">
              <div
                className="d-none d-lg-block"
                style={{
                  paddingTop: '100%',
                  background: `#fff url(${imageUrl}) center no-repeat`,
                  backgroundSize: '130%',
                }}
              />
            </div>
            <div className="flex-fill d-none d-lg-block" />
          </section>
          <section className="section section-3 d-flex flex-fill">
            <div className="bg flex-fill" />
            <div className="flex-fill" />
            <div className="flex-fill" />
          </section>
        </div>
      </div>
    </>
  );
};
