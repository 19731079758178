import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useMatch } from 'react-router';
import { SubscriptionLayout } from './SubscriptionLayout';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  retrieveSubscriptionInfos,
  unsubscribeToList,
} from '../../services/api.service';
import { ISubscriptionInfoToDisplayModel } from 'data/data.types';
import { UnsubscribeErrorComponent } from './UnsubscribeErrorComponent';
import { ToastMessage } from '../Common/ToastMessage';
import { toast } from 'react-toastify';

export const UnsubscribeComponent: FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const match = useMatch('/:lng/subscription/unsubscribe/:code/');
  const code = match?.params.code;
  const lng = match?.params.lng;

  const [unsubscribeInfoError, setUnsubscribeInfoError] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [unsubscribeEmail, setUnsubscribeEmail] = useState<string>();

  useEffect(() => {
    getSubscriptionInfos();
  }, [code]);

  const getSubscriptionInfos = (): void => {
    retrieveSubscriptionInfos(
      code,
      (
        error: any,
        subscriptionInfoToDisplay: ISubscriptionInfoToDisplayModel | null
      ) => {
        if (error) {
          setUnsubscribeInfoError(true);
        } else {
          if (subscriptionInfoToDisplay) {
            setUnsubscribeEmail(subscriptionInfoToDisplay.email);
            setUnsubscribeInfoError(false);
          }
        }
      }
    );
  };

  const handleUnsubscribe = (): void => {
    unsubscribeToList(code, (error: any) => {
      if (error) {
        setHasError(true);
      } else {
        setHasError(false);
        navigate(`/${lng}/subscription/unsubscribe-validated`);
      }
    });
  };

  return (
    <>
      {unsubscribeInfoError ? (
        <UnsubscribeErrorComponent />
      ) : (
        <SubscriptionLayout>
          <div className="d-flex flex-column">
            <h3 className="display-3">
              <FormattedMessage id="subscription.unsubscribe.choice.title" />
            </h3>
            {!unsubscribeInfoError && unsubscribeEmail && (
              <h5 className="display-5">
                <FormattedMessage id="subscription.unsubscribe.choice.subtitle" />
                : {unsubscribeEmail}
              </h5>
            )}
            <div className="mt-4">
              <FormattedMessage id="subscription.unsubscribe.choice.description.row1" />
            </div>
            <div>
              <FormattedMessage id="subscription.unsubscribe.choice.description.row2" />
              .
            </div>
            <div className="d-flex mt-3">
              <button
                className="btn btn-primary btn-lg"
                onClick={handleUnsubscribe}
              >
                <FormattedMessage id="subscription.unsubscribe.choice.btn.unsubscribe" />
              </button>
              <button
                className="btn btn-outline btn-md ml-3"
                onClick={() =>
                  toast.info(
                    <ToastMessage
                      message={formatMessage({
                        id:
                          'subscription.unsubscribe.choice.alert.msg.keep.subscription',
                      })}
                    />
                  )
                }
              >
                <FormattedMessage id="subscription.unsubscribe.choice.btn.keep.subscription" />
              </button>
            </div>
            {hasError && (
              <div className="text-danger">
                <FormattedMessage id="subscription.unsubscribe.error.message" />
              </div>
            )}
          </div>
        </SubscriptionLayout>
      )}
    </>
  );
};
