import React, { FC, useCallback, useRef } from 'react';
import { useGlobalContext } from 'context/global.context';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-account-center': any;
    }
  }
}

export const AccountCenterPublic: FC = () => {
  const context = useGlobalContext();
  const contextRef = useRef(context);
  contextRef.current = context;

  const accountCenterRef = useRef<HTMLElement | null>(null);

  const changeLanguageListener = useCallback((event: any,) => {
    contextRef.current.changeLanguage(event.detail.language);
  }, []);

  const setMountedElement = (mountedElement: HTMLElement | null) => {
    if (!accountCenterRef.current && mountedElement) {
      mountedElement.addEventListener('sgwt-account-center--language-changed', changeLanguageListener);
    } else if (accountCenterRef.current && !mountedElement) {
      accountCenterRef.current.removeEventListener('sgwt-account-center--language-changed', changeLanguageListener);
    }
    accountCenterRef.current = mountedElement;
  };

  return (
    <sgwt-account-center
      available-languages="fr,en"
      authentication="sg-connect-v2"
      mode="sg-markets"
      language={context.language}
      show-languages-selection={true}
      show-sign-in-button={true}
      ref={setMountedElement}
    />
  );
};
