import React, { FC } from 'react';
import { useGlobalContext } from '../../context/global.context';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-help-center': any;
    }
  }
}

export const HelpCenter: FC = () => {
  const { user } = useGlobalContext();
  return (
    <aside>
      {
        user ?
          <sgwt-help-center
            sg-connect-support="sg-connect-v2"
            application-id="info-sgmarkets"
          />
          :
          <sgwt-help-center
            application-id="info-sgmarkets"
          />
      }
    </aside>
  );
};
