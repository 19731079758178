import React, { FC } from 'react';
import { IService } from '../../data/data.types';
import { Link } from 'react-router-dom';
import { servicePath, shareServiceLink } from '../../services/utils.service';
import { trackEvent } from '../../services/piwik.service';
import { ServiceAccessButton } from '../Common/ServiceAccessButton';
import { useGlobalContext } from '../../context/global.context';
import { ResponsiveText } from '../Common/ResponsiveText';
import { InternalBadge } from '../Common/internal-badge/InternalBadgeComponent';

export interface IServiceMiniCardProps {
  service: IService;
}

export const ServiceMiniCard: FC<IServiceMiniCardProps> = ({ service }) => {
  const context = useGlobalContext();
  const link = `/${context.language}/service/${servicePath(service)}`;
  return (
    <div className="mb-4 d-flex pb-2 border-bottom">
      <Link to={link} aria-label={'link to ' + service.name}>
        <div className="d-inline-block border shadow-sm display-3 initials-box mini-card bg-white">
          <span className="text-uppercase font-weight-normal text-light">
            {service.initials}
            {service.isInternal && <InternalBadge />}
          </span>
        </div>
      </Link>

      <Link to={link} className="flex-grow-1 pl-3 no-hover unstyled-link">
        <h4 className="mb-1">{service.name}</h4>
        <ResponsiveText
          tagName="div"
          text={`category.${service.category || 'no-category'}`}
          translate
          className="text-secondary font-weight-medium fs-14"
          mobileClasses="mb-1"
          screenClasses="mb-3"
        />
        <div
          style={{ maxHeight: '2.5rem', overflowY: 'hidden' }}
          className="d-none d-sm-block fs-14"
        >
          {service.text[context.language][0]}
        </div>
        <div
          style={{ maxHeight: '2rem', overflowY: 'hidden' }}
          className="d-sm-none text-small"
        >
          {service.text[context.language][0]}
        </div>
      </Link>

      <div
        className="align-self-end d-none d-sm-flex flex-column text-right"
        style={{ minWidth: '150px' }}
      >
        <div>
          <ServiceAccessButton service={service} />
        </div>
        <div>
          <p
            className="text-secondary float-right mt-2"
            style={{ fontSize: '18px' }}
          >
            <a
              href={shareServiceLink(service, context.language)}
              onClick={() => trackEvent('share', service.serviceKey)}
              className="ml-2"
            >
              <i className="icon">share</i>
            </a>
            {context.internalUser && !context.viewAsClient && (
              <a
                href={service.links.jive}
                onClick={() => trackEvent('jive', service.serviceKey)}
                className="ml-2"
              >
                <i className="icon">chat</i>
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
