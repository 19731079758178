import axios from 'axios';
import React, { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { parseQueryString } from 'common/utils';
import { useGlobalContext } from 'context/global.context';
import { logger } from 'services/utils.service';
import { FooterComponent, SgHeaderComponent } from './RequestAccessComponent';
import { SideComponent, IService } from './layout/SideComponent';
import { RequestAccessForm } from './RequestAccessForm';
import { RequestDemoForm } from './RequestDemoForm';
import { SgLanComponent } from './SgLanComponent';

import './RequestAccessPage.scss';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    SGWTWidgetConfiguration: {
      bus: {
        publish: (key: string, value: string) => void;
      };
    };
  }
}

const apiRequestAccessBase = process.env.REACT_APP_API_REQUEST_ACCESS_BASE;
const sgConnectApiBase = process.env.REACT_APP_SG_CONNECT_ENDPOINT;

const isBlacklistedServiceForPublicRequest = (servicekey: string): boolean => {
  if (!servicekey) {
    return true;
  }
  return ['SGM_ACCOUNT_CENTER'].some(x => x === servicekey.toLocaleUpperCase());
};

interface IRequestAccessPageProps {
  demoForm?: boolean
}

const configAxios = (url: string) => ({
  url,
  method: 'GET',
  timeout: 5000,
  headers: {},
});

export const RequestAccessPage: FC<IRequestAccessPageProps> = ({ demoForm = false }) => {
  const location = useLocation();
  const context = useGlobalContext();
  const params = parseQueryString(location.search);

  const [loading, setLoading] = useState(true);
  const [service, setService] = useState<IService>();
  const [originNetwork, setOriginNetwork] = useState<string>();

  useEffect(() => {
    window.SGWTWidgetConfiguration.bus.publish('global.language', context.language);

    const getService = async () => {
      if (params.returnurl) {
        try {
          const { data } = await axios(configAxios(`${apiRequestAccessBase}/services?url=${btoa(params.returnurl)}`));
          if (isBlacklistedServiceForPublicRequest(data.serviceKey)) {
            return;
          }
          setService({
            name: data.name,
            category: data.category || data.url,
            key: data.serviceKey,
            url: data.infoUrl || '#',
          });
        } catch (error) {
          logger.info('not able to retrieve services', error);
        }
      }
    };

    const getOriginNetwork = async () => {
      try {
        const { data } = await axios(configAxios(`${sgConnectApiBase}/network/api/v1/origin`));
        setOriginNetwork(data['origin-network']);
      } catch (error) {
        logger.error('not able to retrieve network origin', error);
      }
    };

    Promise.all([getService(), getOriginNetwork()])
      .then(() => setLoading(false));
  }, []);

  // Force internet only to facilitate debug & tests
  const isIntranet = !Object.keys(params).includes('forceinternet') && originNetwork === 'intranet';

  return (
    <>
      <link
        rel="stylesheet"
        href="https://shared.sgmarkets.com/sg-bootstrap/v4/4.10.0/sg-bootstrap-standard.min.css"
      />
      <div className="position-relative" style={{ minHeight: '100vh' }}>
        <SgHeaderComponent />
        {!loading && <div className="container-fluid">
          <div className="row">
            <SideComponent service={service} />
            {isIntranet && <SgLanComponent />}
            {!isIntranet && <>
              {!demoForm && <RequestAccessForm
                initialServiceKey={service?.key}
                initialServiceName={service?.name}
              />}
              {demoForm && <RequestDemoForm
                initialServiceKey={service?.key}
                initialServiceName={service?.name}
              />}
            </>}
          </div>
        </div>}
        <FooterComponent />
      </div>
    </>
  );
};
