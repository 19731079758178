import React, { FC } from 'react';
import { useIntlExtended } from 'common/reactIntlExtended';

const SG_CONNECT_API_BASE = process.env.REACT_APP_SG_CONNECT_ENDPOINT;

export const SgLanComponent: FC = () => {
    const { t } = useIntlExtended();
    return (
        <div className="col-12">
            <main className="mx-auto p-5 mb-5 text-justify" style={{ marginTop: '10rem', maxWidth: 600 }} >
                <div className="bg-secondary flex-center rounded-circle mb-4" style={{ height: 48, width: 48 }}>
                    <i className="icon icon-md">person_add</i>
                </div>

                <h1 className="mb-2">
                    {t('request-access.sg-lan.title')}
                </h1>
                <h4 className="text-secondary mb-4">
                    {t('request-access.sg-lan.subtitle')}
                </h4>
                <div className="d-flex mb-4">
                    <i className="icon icon-sm text-secondary mr-2">info_outline</i>
                    <p className="text-secondary m-0">
                        {t('request-access.sg-lan.description')}
                    </p>
                </div>
                <a href={`${SG_CONNECT_API_BASE}/#/forgot-password`} rel="noopener noreferrer" className="btn btn-primary btn-xl btn-block">
                    {t('request-access.sg-lan.activate-btn')}
                </a>
            </main>
        </div>
    );
};
