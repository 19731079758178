import React, { ReactNode, useId, useRef } from 'react';
import { useIntlExtended } from 'common/reactIntlExtended';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors } from 'react-hook-form';
import classNames from 'classnames';
import { DefaultTooltip } from 'components/Common/Tooltip';
import { getCustomErrorComponent } from '../errors/customErrorComponent';

interface IInputFormProps<T extends FieldValues> {
    labelId?: string;
    placeholderId?: string;
    helpId?: string;
    errorId?: string;
    type?: 'text' | 'email';
    name: FieldPath<T>;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    disabled?: boolean;
}

export const InputForm = <T extends FieldValues>({ labelId, type = 'text', name, register, errors, placeholderId, helpId, disabled }: IInputFormProps<T>): ReactNode => {
    const { t } = useIntlExtended();
    const inputId = useId();
    const helpRef = useRef(null);

    const error = errors[name]?.message;
    const inputClassName = classNames('form-control form-control-xl ', {
        'is-invalid': error,
    });

    return <div className="form-group mb-4">
        {labelId && <label className="mb-1" htmlFor={inputId}>
            {t(labelId)}
        </label>}
        {helpId && <>
            <i ref={helpRef} className="btn btn-link icon ml-2 p-0">
                help
            </i>
            <DefaultTooltip target={helpRef} text={t(helpId)} />
        </>}
        <input
            id={inputId}
            type={type}
            className={inputClassName}
            placeholder={placeholderId && t(placeholderId)}
            disabled={disabled}
            {...register(name)}
        />
        {typeof error === 'string' && <div className="invalid-feedback">
            {getCustomErrorComponent(error) ?? t(`${error}`)}
        </div>}
    </div>;
};
