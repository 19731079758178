import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useGlobalContext } from '../../../context/global.context';
import { ResponsiveText } from '../../Common/ResponsiveText';
import { WrappedFormattedMessage } from '../../Common/WrappedFormattedMessage';
import { IServiceTheme } from '../../../data/data.types';

interface IHeadBannerSectionProps {
  theme: IServiceTheme;
}

export const HeadBannerSection: FC<IHeadBannerSectionProps> = ({ theme }) => {
  const { language } = useGlobalContext();

  return (
    <div className={`border-bottom theme-bg-${theme.id} row`}>
      <div className="container pt-3">
        <div className="row">
          <div className="col-12 col-sm-10 pt-3">
            <Link
              to={`/${language}/category/markets`}
              className="text-large"
            >
              <h4 className="text-secondary">
                <i className="icon icon-md pb-1" style={{ opacity: 0.7 }}>
                  keyboard_arrow_left
                </i>
                <FormattedMessage id={'category.markets'} />
              </h4>
            </Link>

            <WrappedFormattedMessage
              tag="h1"
              id={`theme.${theme.id}`}
              className="display-3 mt-2 text-truncate"
            />
            <ResponsiveText
              tagName="div"
              text={`theme.${theme.id}.description`}
              translate
              screenClasses="text-large w-75 my-3"
              mobileClasses="fs-14 my-3"
            />

            <div className="mb-3 d-none d-sm-block">
              {theme.tags.map((tag: string) => (
                <span
                  className="badge badge-lg badge-primary-alt badge-prepend-square mr-1 mb-1"
                  key={tag}
                >
                  <FormattedMessage id={`tag.${tag}`} />
                </span>
              ))}
            </div>
          </div>
          <div className="col-sm-2 d-none d-sm-block align-self-center text-center">
            <img src={`/images/themes/${theme.id}.svg`} alt={theme.id} />
          </div>
        </div>
      </div>
    </div>
  );
};
