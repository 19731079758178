import { IService } from '../data/data.types';
/**
 * Define the list of compatible platforms for a services. If the "compatiblePlatforms" property is NOT set, then all
 * platforms are considered as compatible. Otherwise, ONLY the platform for which the corresponding property is set to
 * `true` will be considered as compatible.
 */
export function getCompatiblePlatforms(service: IService): string[] {
  const platforms: string[] = [];
  const compat = service.compatiblePlatforms;
  if (compat) {
    if (compat.hasOwnProperty('desktop') && compat.desktop) {
      platforms.push('laptop');
    }
    if (compat.hasOwnProperty('tablet') && compat.tablet) {
      platforms.push('tablet_mac');
    }
    if (compat.hasOwnProperty('smartphone') && compat.smartphone) {
      platforms.push('smartphone');
    }
    return platforms;
  }
  return ['laptop', 'tablet_mac', 'smartphone'];
}
