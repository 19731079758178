import {
  getUserConsentOnQumu,
  loadQumuScript,
} from 'components/CookieConsent/cookieConsent';
import { trackEvent } from './piwik.service';
/**
 * Initialize a Qumu player.
 * @param {string} guid
 * @param {string} selector
 * @param {() => void} onPlay
 * @param {() => void} onUnload
 */
export function initializeQumuPlayer(
  guid: string,
  selector: string,
  onPlay: () => void,
  onUnload: () => void
): void {
  getUserConsentOnQumu().then((consent: boolean) => {
    if (!consent) {
      return;
    }
    loadQumuScript().then(() => {
      initialize(guid, selector, onPlay, onUnload);
    });
  });
}

function initialize(
  guid: string,
  selector: string,
  onPlay: () => void,
  onUnload: () => void
): void {
  const widget = (window as any).KV.widget({
    guid,
    type: 'playback',
    playerType: 'full',
    showInfoPanel: false,
    embed: true,
    selector,
  });
  if (!widget || !widget.api) {
    // TODO Why?
    return;
  }
  const api = widget.api;
  api.init(() => {
    // Video will be played...
    api.bind('play', () => {
      onPlay();
      // Video is played. Add a Piwik event!
      trackEvent('video', 'play');
    });
    api.bind('unload', () => {
      onUnload();
      setTimeout(() => {
        api.command('destroy');
      }, 1000);
    });
  });
}
