import * as React from 'react';
import Carousel from 'reactstrap/lib/Carousel';
import CarouselIndicators from 'reactstrap/lib/CarouselIndicators';
import CarouselItem from 'reactstrap/lib/CarouselItem';
import CarouselControl from 'reactstrap/lib/CarouselControl';
import { initializeQumuPlayer } from '../../services/qumu.service';
import { NoVideo } from '../CookieConsent/NoVideo';
import {
  addUserConsentSettingsCallback,
  getUserConsentOnQumu,
  loadQumuScript,
  removeUserConsentSettingsCallback,
} from '../CookieConsent/cookieConsent';

export interface IImageCarouselProps {
  images: any[];
}

export interface IImageCarouselState {
  activeIndex: number;
}

export class ImagesCarousel extends React.Component<
  IImageCarouselProps,
  IImageCarouselState
> {
  private animating = false;
  private playVideo = false;
  private qumuConsent = false;

  constructor(props: IImageCarouselProps) {
    super(props);
    this.state = {
      activeIndex: 0,
    };

    this.updateQumuConsent = this.updateQumuConsent.bind(this);
    this.updateConsent = this.updateConsent.bind(this);
  }

  updateConsent() {
    this.updateQumuConsent(true);
  }

  componentDidMount() {
    this.updateQumuConsent(false);
    addUserConsentSettingsCallback(this.updateConsent);
  }

  componentWillUnmount() {
    removeUserConsentSettingsCallback(this.updateConsent);
  }

  updateQumuConsent(reload: boolean) {
    getUserConsentOnQumu().then((consent: boolean) => {
      this.qumuConsent = consent;
      if (reload) {
        this.forceUpdate();
      }
    });
  }

  onExiting(): void {
    this.animating = true;
  }

  onExited(): void {
    this.animating = false;
  }

  next(force: boolean): void {
    if (!force && (this.animating || this.playVideo)) {
      return;
    }
    const nextIndex =
      this.state.activeIndex === this.props.images.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous(force: boolean): void {
    if (!force && (this.animating || this.playVideo)) {
      return;
    }
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.images.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex: number): void {
    if (this.animating) {
      return;
    }
    this.setState({ activeIndex: newIndex });
  }

  private initQumuPlayer(guid: string, selector: string): void {
    initializeQumuPlayer(
      guid,
      selector,
      () => {
        this.playVideo = true;
      },
      () => {
        this.playVideo = false;
      }
    );
  }

  render() {
    const { images } = this.props;
    const { activeIndex } = this.state;

    const slides = images.map((item, index) => {
      const nextItem = images[index + 1 === images.length ? 0 : index + 1];
      if (item.video && this.qumuConsent) {
        loadQumuScript().then(() => {
          this.initQumuPlayer(item.video, '#qumu-player-slide');
          this.initQumuPlayer(item.video, '#qumu-player-next-slide');
        });
      }
      return (
        <CarouselItem
          onExiting={() => this.onExiting()}
          onExited={() => this.onExited()}
          key={`image-carousel-${index}`}
        >
          <div className="d-flex embed-responsive-16by9 my-5">
            {item.video ? (
              this.qumuConsent ? (
                <div className="mr-3 w-100">
                  <div
                    id="qumu-player-slide"
                    className="border border-secondary"
                  />
                </div>
              ) : (
                <NoVideo />
              )
            ) : (
              <img src={item.source} alt={item.alt} className="mr-3" />
            )}
            {nextItem.video ? (
              this.qumuConsent ? (
                <div className="mr-3 w-100" style={{ opacity: 0.5 }}>
                  <div
                    id="qumu-player-next-slide"
                    className="border border-secondary"
                  />
                </div>
              ) : (
                <NoVideo />
              )
            ) : (
              <img
                src={nextItem.source}
                alt={nextItem.alt}
                style={{ opacity: 0.5 }}
              />
            )}
          </div>
        </CarouselItem>
      );
    });

    return (
      <Carousel
        interval={false}
        activeIndex={activeIndex}
        next={() => this.next(false)}
        previous={() => this.previous(false)}
      >
        <CarouselIndicators
          items={images}
          activeIndex={activeIndex}
          onClickHandler={(n: number) => this.goToIndex(n)}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={() => this.previous(true)}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={() => this.next(true)}
        />
      </Carousel>
    );
  }
}
