import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { IService } from '../../data/data.types';
import { sortSubCategories } from '../../services/utils.service';
import { ServicesColumn } from '../Common/ServicesColumn';

export interface ICategoryServicesListProps {
  category: string;
  displayedServices: string[];
  subCategories: { [key: string]: IService[] };
  themes: string[];
}

export const CategoryServicesList = (props: ICategoryServicesListProps) => {
  const { category, displayedServices, subCategories, themes } = props;

  // To avoid shifting of the category title (which is due to the fact that the title is vertically centered with
  // the panel - which height is dependent of the number of services to display), we search for the maximum number
  // of services to display in one column, which will define the height of the whole <div>.
  // cf. issue #34 (https://sgithub.fr.world.socgen/SGWT-Projects/new-info/issues/34)
  let maxNumberOfServicesPerColumn = 0;
  Object.keys(subCategories).map((sub: string) => {
    for (const t of themes) {
      const l = subCategories[sub].filter(
        (s: IService) => s.themes.indexOf(t) > -1
      ).length;
      maxNumberOfServicesPerColumn = Math.max(maxNumberOfServicesPerColumn, l);
    }
  });

  return (
    <>
      {/* Screen view */}
      <div className="col-12 d-none d-sm-flex px-0">
        {Object.keys(subCategories)
          .sort(sortSubCategories)
          .map(sub => {
            const servicesList = subCategories[sub].filter(
              service => displayedServices.indexOf(service.serviceKey) > -1
            );
            if (servicesList.length === 0) {
              return null;
            }
            return (
              <div
                className="col-md-4 mb-4"
                key={`sub-category-content-${category}-${sub}`}
              >
                <div className="md-4 text-secondary mb-4 theme-name fs-14">
                  <FormattedMessage id={`sub-category.${sub}`} />
                </div>
                <div className="d-sm-none">
                  <ServicesColumn services={servicesList} />
                </div>
                <div
                  className="d-none d-sm-block"
                  style={{
                    minHeight: `${maxNumberOfServicesPerColumn * 2.5}rem`,
                  }}
                >
                  <ServicesColumn services={servicesList} />
                </div>
              </div>
            );
          })}
      </div>

      {/* Mobile view */}
      <div className="row d-sm-none">
        <div className="pb-2 mobile-horizontal-scroll">
          <div className="d-inline-flex ml-3">
            {Object.keys(subCategories)
              .sort(sortSubCategories)
              .map(sub => {
                const servicesList = subCategories[sub].filter(
                  service => displayedServices.indexOf(service.serviceKey) > -1
                );
                return (
                  <div
                    style={{ width: '60vw' }}
                    key={`sub-category-content-${category}-${sub}`}
                  >
                    <div className="md-4 text-secondary text-secondary mb-4">
                      <FormattedMessage id={`sub-category.${sub}`} />
                    </div>
                    <div className="d-sm-none">
                      <ServicesColumn services={servicesList} />
                    </div>
                    <div
                      className="d-none d-sm-block"
                      style={{
                        minHeight: `${maxNumberOfServicesPerColumn * 2.5}rem`,
                      }}
                    >
                      <ServicesColumn services={servicesList} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
